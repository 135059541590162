$browser-context: 16; // Default

//funtions to transform pixels to em
@function em($pixels, $context: $browser-context) {
    @return #{$pixels / $context}em;
}

//helpers margins/paddings
@each $nameType,
$type in ("m": margin, "p": padding) {

    @each $namePos,
    $position in ("t": top, "r": right, "b": bottom, "l": left) {
        $number: 0px;

        @each $name in "none",
        "xs",
        "sm",
        "md",
        "lg",
        "xl",
        "xxl",
        "xxxl" {
            @if $name =="none" {
                .n-#{$nameType}-#{$namePos} {
                    #{$type}-#{$position}: 0px !important;
                }
            }

            @else {
                .#{$nameType}-#{$namePos}-#{$name} {
                    #{$type}-#{$position}: $number  !important;
                }

                //bg-acc
                .section-#{$name} {
                    padding: $number 0;
                }
            }

            $number: $number + 10;
        }

        //each
    }

    //each
}

.n-m-t {
    margin-top: 0 !important;
}

//helper to generate fonts from 1 to 100
@for $i from 1 to 100 {
    .font-#{$i} {
        //font-size: em(#{$i}) !important;
        //line-height: em(#{$i + 4}) !important;
        font-size: #{$i}px !important;
        line-height: #{$i + 4}px !important;
    }
}

@for $i from 1 to 100 {
    .spacer-#{$i} {
        height: #{$i}px;
        clear: both;

        &:after,
        &:before {
            @extend %clear;
        }
    }
}

//to do a top triangle
@mixin top-traingle-menu($top: initial, $left: initial, $right: initial, $bottom: initial) {
    &:after {
        content: "";
        position: absolute;
        top: $top;
        left: $left;
        bottom: $bottom;
        right: $right;
        margin-left: 43px;
        width: 0px;
        height: 0px;
        border: 1em solid black;
        border-color: transparent transparent #fff #fff;
        transform-origin: 0 0;
        transform: rotate(135deg);
        box-shadow: -3px 3px 5px 0 rgba(0, 0, 0, 0.1);
    }
}

.visible-xs-flex,
.visible-sm-flex,
.visible-md-flex,
.visible-lg-flex {
    display: none !important;
}

@media (min-width: 1200px) {
    .visible-lg-flex {
        display: flex !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-flex {
        display: flex !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-flex {
        display: flex !important;
    }
}

@media (max-width: 767px){
    .visible-xs-flex {
        display: flex !important;
    }
}

.no-box-shadow {
    box-shadow: none !important;
}

.wrapper-cursor-disabled {
    cursor: not-allowed;
}