form.new-design {

    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }
}

.new-design {
    .floating-section {
        margin-bottom: 24px;
        position: relative;

        .floating-group {
            position: relative;

            .black-label {
                color: #5C6F7C !important;
                padding-bottom: 0px;
            }
        }

        input,
        select,
        textarea {
            width: 100%;
            padding: 10px 16px;
            //-moz-padding-start: 12px;
            outline: none;
            border-radius: 7px;
            border: 1px solid $color-primary-beige-medium;
            background-color: $color-primary-beige;
            line-height: 1.25;
            height: 65px;
            font-size: 18px;

            &::placeholder {
                opacity: 0;
            }

            ~.eye-wrapper {
                position: absolute;
                cursor: pointer;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                display: flex;

                .eye-icon {
                    position: relative;
                    background: url("../images/eye-open-svg.svg") no-repeat;
                    height: 15px;
                    width: 23px;
                    margin: 0 15px;

                    &.open {
                        background: url("../images/eye-not-open-svg.svg") no-repeat;
                        height: 20px;
                        width: 23px;
                    }
                }
            }

            &:focus,
            &:not(:placeholder-shown) {
                padding-top: 20px;
                padding-bottom: 0;
            }

            &.error,
            &:focus.error {
                border: 2px solid $color-new-red;
            }

            &:focus {
                border: 2px solid $color-blue;
            }
        }

        label {
            position: absolute;
            left: 18px;
            transform: translateY(19px);
            font-size: $floating-label-font-size;
            transition-duration: 300ms;
            color: $color-alto-medium !important;
            font-weight: 400;
            top: 0;
        }

        input:focus,
        input:not(:placeholder-shown) {
            ~label {
                font-size: $floating-label-font-size-small;
                transform: translateY(8px);

                &.error {
                    position: relative;
                }
            }
        }

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            font-size: $floating-label-font-size;

            &:focus {
                outline: none;
            }

            &::-ms-expand {
                display: none;
            }

            ~label {
                pointer-events: none;
                background-color: $color-primary-beige;
                margin-left: -1px;
                padding-left: 1px;
                width: 85%;
            }

            &:not([multiple]):not([size]) {
                background: $color-primary-beige url("../images/arrow-down-gray.png") no-repeat right 10px center;
                background-size: 11px;
                padding-right: 30px;
            }

            &:not(:placeholder-shown) {
                padding-top: 0px;
            }

            &:not([value=""]) {
                padding-top: 20px;
            }

            &[value=""]:focus~label {
                top: 0px;
            }

            &:not([value=""]):valid~label {
                top: -12px;
                font-size: $floating-label-font-size-small;
            }
        }

        .fix-floating-label {
            &:before {
                content: "";
                position: absolute;
                top: 1px;
                left: 2px;
                width: calc(100% - 20px);
                height: 32px;
                background-color: $color-primary-beige;
                border-radius: 7px;
            }

            &.fs:before {
                top: 2px;
            }
        }

        textarea {
            height: auto;
            resize: none;

            &:focus,
            &:not(:placeholder-shown) {
                padding-top: 30px;

                ~label {
                    font-size: $floating-label-font-size-small;
                    transform: translateY(8px);
                    transition-duration: 300ms;
                }
            }
        }

        .password-checklist {
            top: calc(100% + 10px);
            width: 100%;
            border-radius: 5px;
            .checklist {
                list-style: none;
                padding: 0px;
                margin: 0px;
                li{
                    padding-left: 30px;
                    margin: 0px;
                    display: flex;
                    align-items: center;
                    &:not(.list-item){
                        padding-left: 20px;
                    }
                }
                .list-item {
                    
                    &::before {
                      content: '\f00d';
                      font-family: FontAwesome;
                      display: inline-block;
                      margin: 8px 0;
                      margin-left: -30px;
                      width: 20px;
                      color: red;
                    }
                  
                    &.checked {
                      opacity: 0.5;
                  
                      &::before {
                        content: '\f00c';
                        color: $color-blue;
                      }
                    }
                  }
              }
          }
          
    }

    .floating-messages {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.05em;
        color: #5a5a5a;

        >div {
            margin: 10px 20px;
        }
    }

    .floating-error-messages {

        .error,
        .server-error {
            color: $color-new-red;
            font-size: 14px;
            font-weight: 600;
        }

        .alert-danger {
            margin-bottom: 1rem;
            border-radius: 0.375rem;
            text-align: left;
            padding: 1rem;
        }
    }

    .floating-info-messages,
    .floating-submit-messages {
        display: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus,
    select:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
    }

    .full-date {
        display: table;

        .date-group {
            margin-right: 10px;
            width: 90px;
            position: relative;
            float: left;

            @include respond-to("md") {
                width: 120px;
                margin-right: 20px;
            }

            &:last-child {
                margin-right: 0px;

                @include respond-to("md") {
                    width: 180px;
                }
            }
        }

        input {
            &:focus {
                &::placeholder {
                    opacity: 1 !important;
                    color: $color-alto-medium;
                }
            }
        }
    }

    &.form {

        input::-ms-reveal,
        input::-ms-clear {
            display: none;
        }

        display: flex;
        justify-content: center;
        padding: 40px 10px;
        max-width: 710px;

        @include respond-to("md") {
            padding: 40px;
        }

        .step-header {
            text-align: center;
            font-weight: 700;
            width: 95%;
            margin: 0 auto;


            h1 {
                @extend %h1;
                font-weight: 700;
                letter-spacing: 0;
                color: #37424A;
            }


            h1, h5,
            p {
                margin-bottom: 25px;
            }
        }

        form {
            padding-top: 0;
            will-change: transform;

            .fa-spinner {
                font-size: 20px;
                margin-left: 8px;
            }

            .form-link {
                color: #02b3ff;
                font-weight: 700;
                width: fit-content;
            }

            .custom-checkbox .check-text {
                font-size: 17.5px;
                font-weight: 400;
                color: #5a5a5a;
                top: -6px;
                position: relative;
            }

            .radio-group {
                .single-label {
                    float: left;
                    color: $color-alto-medium;
                    font-size: $floating-label-font-size;
                    padding-right: 25px;
                }

                .custom-radio {
                    font-size: 16px;
                }
            }

            .form-section {
                margin-bottom: 60px;

                .section-name {
                    display: flex;
                    align-items: baseline;
                    font-weight: 600;
                    margin-bottom: 25px;
                    font-size: 17px;

                    @include respond-to("md") {
                        font-size: 18px;
                    }
                }

                .line {
                    flex: 1;
                    height: 1px;
                    background-color: $color-alto-light;
                    margin-left: 16px;
                }
            }

            .text-small {
                margin-top: 10px;
            }
        }

        .avatar-panel {
            text-align: right;
            position: relative;

            img {
                width: 120px;
                height: 120px;
                border-radius: 50%;

                @include respond-to("xs-down") {
                    width: 90px;
                    height: 90px;
                }
            }

            .form-link {
                display: block;
                width: 100%;
                padding-top: 5px;

                @include respond-to("xs-down") {
                    text-align: center;
                }
            }
        }
    }

    .back-link {
        display: block;
        font-weight: 400 !important;
        font-size: 15px;
        margin-bottom: 14px;

        i {
            font-size: 10px;
            margin-right: 4px;
            vertical-align: middle;
        }
    }

    .messageLink {
        cursor: pointer;

        &.disabled {
            pointer-events: none;
            box-shadow: none;
            opacity: 0.65;
            cursor: none;
        }
    }

    #update-profile .step-header {
        text-align: left;
        width: 100%;
        padding-bottom: 35px;
        align-items: center;

        p {
            margin-bottom: 0;
        }

        div[class^="col-"] {
            padding: 0;
        }

        .member {
            font-weight: bold;
        }

        .not-member,
        .already-asociated {
            font-size: 16px;
        }
        h2{
            @extend %h2;
            font-weight: bold;
            color: #37424A;
            margin-bottom: 25px;
        }
    }

    input[readonly] {
        cursor: not-allowed;
        opacity: 0.6;
    }
}

#more-about-you {
    .new-design.form {
        padding: 0;
        max-width: inherit;

        #CBY {
            .floating-section {
                select {
                    ~label {
                        width: 78%;
                    }
                }
            }
        }
    }
}

#member-welcome {
    #imsid {
        width: 100%;

        @include respond-to("md") {
            width: 328px;
        }
    }
}