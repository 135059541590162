form {
    padding-top: 50px;
    .dob-error {
        @extend %form-errors;
        display: none;
    }
    .warning {
        font-size: 14px;
        line-height: 20px;
        color: darken($color-blue, 10%);
        display: none;
    }

    &.calcas-hero-form {
        padding-top: 12px;
    }
} //form

.showpass {
    cursor: pointer;
    font-size: 14px;
}

.form-group {
    label {
        @extend %font-normal;
        @extend .text-alpha;
        &.error {
            @extend %form-errors;
            padding-bottom: 0;
        }
    }
    input.form-control:not(.btn),
    select.form-control:not(.btn) {
        background: $color-gray;
        border: none;
        box-shadow: none;
        height: 40px;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 3px;
        &:focus {
            border: 2px solid $color-blue;
        }
        &.error,
        &.errors {
            border: 2px solid $color-red !important;
            border-radius: 3px;
            &:focus {
                border: 2px solid $color-red !important;
            }
        }
    } //input
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
        background: $color-gray url("../images/chevron_down-10x10.png")
            no-repeat right 5px center !important;
        option {
            background: white;
            &:hover {
                background: black;
            }
        }
        @include respond-to("xxs") {
            font-size: 11px;
            padding: 6px !important;
        }
        &:focus {
            border: none !important;
        }
        &.select-big {
            font-size: 26px;
            height: 60px !important;
            margin-bottom: 10px;
            color: rgba(black, 0.3);
            option {
                font-size: 15px;
                color: black;
            }
        } //big
    } //select
    .input-big {
        font-size: 26px;
        height: 60px !important;
        margin-bottom: 10px;
        color: rgba(black, 0.3);
    }

    textarea {
        resize: none;
        background: $color-gray;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-shadow: none;
        &:focus {
            border: 2px solid $color-blue;
        }
    }
}

.form-control.error {
    outline: 0;
    border: 2px solid $color-red;
    &:hover,
    &:focus {
        outline: 0;
        border: 2px solid $color-red;
        box-shadow: none;
    }
}

i.tooltipMark {
    font-size: 35px;
    padding-left: 5px;
    cursor: pointer;
}

label {
    &.error {
        @extend %form-errors;
    }
} //label

.server-errors {
    display: none;
    span {
        @extend %form-errors;
    }
}

//custom-checkbox and radio
.custom-checkbox,
.custom-radio {
    display: block;
    position: relative !important;
    padding-left: 35px;
    margin-bottom: 12px;
    transform: none !important;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
}
label.custom-checkbox.blue + p {
    text-indent: 0px;
    padding-left: 40px;
}
.custom-radio {
    .checkmark {
        border-radius: 50%;
    }
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #eee;
    border-radius: 5px;
    border: 2px solid darken($color-gray-plain, 20%);
}
.custom-checkbox,
.custom-radio {
    &:hover input ~ .checkmark {
        background-color: #ccc;
    }
    &.blue {
        input:checked ~ .checkmark {
            background-color: $color-blue;
            background: $color-blue url("../images/path-12x12.png") 50% 50%
                no-repeat;
            border: none;
        }
    } //blue
}

.custom-radio {
    display: inline-block !important;
    vertical-align: middle;
    padding-right: 30px;
    &.blue {
        input:checked ~ .checkmark {
            background: #eee;
            border: 2px solid darken($color-gray-plain, 20%);
            &:after {
                content: " ";
                width: 14px;
                height: 14px;
                display: block;
                border-radius: 50%;
                position: relative;
                background: $color-blue;
                margin: 3px;
            }
        }
    } //blue
}
//custom-checkbox and radio

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 22px;

    ~ span {
      @extend %ellipsis;
      font-size: 1rem;
      color: $color-dark-gray !important;
      padding-left: 10px;
    }

    /* Hide default HTML checkbox */
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

/* The slider */
.slider-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-alto-light;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        background-color: white;
        transition: 0.4s;
        right: 22px;
        top: 2px;
    }
    /* Rounded sliders */
    &.round {
        border-radius: 34px;

        &:before {
            border-radius: 50%;
        }
    }
}

input:checked + .slider-toggle {
    background-color: $color-blue;
}

input:focus + .slider-toggle {
    box-shadow: 0 0 1px $color-blue;
}

input:checked + .slider-toggle:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

.select-error {
    display: none;
    span.error {
        @extend %form-errors;
        padding-bottom: 0;
    }
}

.form-nav {
    color: black;
    font-size: 12px;
    font-weight: 700;
    i {
        padding-right: 10px;
    }
    span {
        font-family: $font-family;
    }
}

.ben-payout-error {
    color: darkorange;
    font-size: 14px;
    font-weight: 700;
    padding-top: 6px;
    display: none;
}

.group.OtherEntity {
    display: none;
}
