.modal-header,
.modal-footer {
    border: none;
}

.modal-backdrop.in {
    filter: alpha(opacity=70);
    opacity: 0.7;
}

.close {
    opacity: 1;
    font-size: 30px;
}

.modalForms {
    &.no-footer {
        .modal-body {
            padding-bottom: 80px;
        }
    }

    //no-footer
    .modal-body {
        text-align: center;
        padding-top: 0;

        @include respond-to("md") {
            padding-left: 100px;
            padding-right: 100px;
        }

        p {
            font-size: 14px;
            padding: 30px 0 50px 0;
            text-align: center;

            @include respond-to("md") {
                font-size: 21px;
            }
        }

        .btn {
            font-size: 14px;

            @include respond-to("md") {
                font-size: 16px;
            }
        }
    }

    //modal-body
    .modal-footer {
        text-align: center;
        padding-bottom: 60px;

        @include respond-to("md") {
            padding: 40px 60px 50px 60px;
            text-align: left;
        }

        p {
            padding-top: 10px;
            font-size: 14px;

            @include respond-to("md") {
                font-size: 16px;
            }
        }

        //p
        .share {
            a {
                color: rgba(102, 102, 102, 0.7) !important;
                width: 32px;
                text-align: center;
                padding: 0 13px;
                font-size: 25px;
                vertical-align: bottom;
                @extend %transition;

                &:hover {
                    color: rgba(102, 102, 102, 1) !important;
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }

            @include respond-to("md") {
                float: right;
            }
        }
    }

    //modal-footer
}

//registrationModal

.modal-home {
    .modal-dialog {
        height: 100vh !important;
        display: flex;
    }

    .modal-content {
        margin: auto !important;
        height: fit-content !important;
    }

    .modal-header {
        position: relative;
        padding-left: 40px;
        padding-right: 40px;
    }

    .modal-body {
        padding-left: 40px;
        padding-right: 40px;

        p {
            line-height: 1.5 !important;
        }
    }
}

.sessionModal {

    .modal-dialog {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        min-height: calc(100% - (0.5rem * 2));

        .modal-content{
            border-radius: 8px 8px 6px 6px;
            .modal-body {
                padding: 0px;
        
                .close {
                    position: absolute;
                    right: -15px;
                    top: -15px;
                    background: black;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
        
                    @include respond-to("md-down") {
                        right: 5px;
                        top: 5px;
                    }
        
                    span {
                        color: white;
                    }
                }
        
                .inner-banner {
                    color: white;
                }
        
                .inner-text {
                    padding: 15px;
                }

                a{
                    img{
                        border-radius: 6px 6px 0px 0px;
                    }
                }
            }
        }
    }

   
}