body,
html {
    font-family: $font-family;
    font-size: $font-base;
    line-height: 1.5;
    width: 100%;


    &-fixed {
        padding-bottom: 90px;

        @include respond-to("md") {
            padding-bottom: 0;
        }
    }

    p {
        margin: 5px 0;
        padding: 5px 0;
        word-break: break-word;
    }
}

body{
    overflow-anchor: none;
}

//body

select::-ms-expand {
    display: none;
}

.article {
    a {
        text-decoration: underline;
        color: black;
    }
}

.container {
    padding-left: 20px;
    padding-right: 20px;
}

.loader {
    display: none;
}

.full-width-image-bg {
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.clearfix {
    display: inline-block;
}

/* start commented backslash hack \*/
* html .clearfix {
    height: 1%;
}

.clearfix {
    display: block;
}

.flex-center {
    display: flex;
    align-items: center;
}

.flex-center-column {
    flex-direction: column;
    display: flex;
    justify-content: center;
}

/* close commented backslash hack */

.section-indicator {
    font-size: em(12);
    @extend %text-semibold;
    text-align: center;
    text-transform: uppercase;
    padding: 0 0 15px 0;
}

.tooltipText {
    text-decoration: underline;
    text-underline-position: under;
    cursor: pointer;
}

.c-soon {
    font-size: em(12);
    color: $color-aquamarine;
}

.title {
    letter-spacing: -1px;
}

.box {
    margin-top: 20px;

    &-border-top {
        border-top: 2px solid $color-gray;

        h3 {
            padding: 10px 0;
        }

        &.purple {
            border-color: $color-purple;
        }

        &.gray {
            border-color: $color-black-alpha;
        }
    }
}

.col-centered {
    float: none;
    margin: 0 auto;

    &-flex {
        display: flex;
        justify-content: center;
    }
}

.no {
    &-padding {
        padding: 0 !important;
    }

    &-margin {
        margin: 0 !important;

        &-auto {
            margin: 0 auto !important;
        }
    }
}

.scWebEditInput {
    width: 100%;
}

.attribution {
    padding: 20px 0;
    display: inline-block;
    zoom: 1;
    width: 100%;
    float: left;
    width: auto;

    @include respond-to("md") {
        float: right;
    }

    .at-icon {
        fill: rgba(129, 147, 159, 1);
    }

    p.text-small {
        float: left;
        margin-top: 11px;
        display: inline-block;
        margin-right: 5px;
        color: rgba(129, 147, 159, 1) !important;
    }

    .addthis_inline_share_toolbox {
        float: right;
        width: auto;
        clear: none !important;
    }
}

section:not(.section-cta) {
    .attribution {
        padding: 0;
    }

    p.text-small {
        margin-top: 37px;
    }
}

.section {
    padding: 30px 0;

    &-normal {
        padding: 30px 0;

        @include respond-to("md") {
            padding: 80px 0;

            &.contest {
                padding: 10px 0;
            }
        }

        &.slider-section {
            padding: 80px 0;

            .container {
                padding-right: 5px;
                padding-right: 0;
                width: 100%;

                @include respond-to("md") {
                    width: 970px;
                }

                @include respond-to("lg") {
                    width: 1170px;
                }

                h2.header {
                    padding-right: 30px;
                    @extend %h2;

                    @include respond-to("md") {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    &-reduced {
        padding: 10px 0;

        @include respond-to("md") {
            padding: 20px 0;

            &.contest {
                padding: 10px 0;
            }
        }
    }

    //normal
    &-cta {
        padding: 0 0 30px 0;

        @include respond-to("md") {
            padding: 60px 0 30px 0;
        }

        div[class*="col-"] {

            >.img-fit,
            >.img-responsive {
                margin-bottom: 40px;

                @include respond-to("md") {
                    margin-bottom: 0;
                }
            }
        }

        >.container {
            padding: 0;
            margin: 0;

            @include respond-to("sm") {
                padding-right: 15px;
                padding-left: 15px;
                margin-right: auto;
                margin-left: auto;
            }

            >.col-md-6,
            >.col-md-12 {
                padding-right: 20px;
                padding-left: 20px;

                @include respond-to("sm") {
                    padding-right: 15px;
                    padding-left: 15px;
                }

                >.disclaimer {
                    margin-right: 0;
                    margin-left: 0;
                }
            }

            >.col-md-6:first-of-type {
                padding-right: 0;
                padding-left: 0;

                @include respond-to("sm") {
                    padding-right: 15px;
                    padding-left: 15px;
                }
            }

            .eye-brow {
                color: black;
                line-height: 1;

                @include respond-to("md") {
                    margin: 0;
                    padding: 0;
                }
            }

            h1 {
                padding-top: 0 !important;
            }

            h3 {
                margin: 30px 0;
            }

            .form-group {
                margin: 0px 0 30px 0;
            }

            .info-card {
                margin: 20px 0 10px 0;
            }

            .btn {
                margin: 10px 0 18px 0;
            }

            .label {
                text-align: left;
                padding: 0;

                @include respond-to("md") {
                    padding: 0;
                }

                label {
                    color: rgba(8, 32, 45, 0.5);
                    margin: 0;
                    margin-bottom: 10px;
                    font-size: 16px;

                    @include respond-to("sm") {
                        margin-top: 10px;
                        font-size: 14px;
                        text-align: right;
                    }

                    @include respond-to("lg") {
                        font-size: 16px;
                    }
                }
            }

            label.custom-checkbox.blue.pull-left {
                margin-top: 10px;

                @include respond-to("md") {
                    margin-top: 0;
                }
            }

            .disclaimer {
                color: $color-black-alpha;
                line-height: 1.4;

                @include respond-to("md") {
                    // padding-top: 50px;
                }

                a {
                    color: $color-black-alpha;
                }
            }
            [class*="col-"]>.btn {
                min-width: 100%;
                white-space: normal;
                @include respond-to("md") {
                    min-width: 236px;
                }
            }
            form{
                .col-md-6{
                    padding: 0;
                }
                input[type=submit]{
                    &.btn {
                        min-width: 100%;
                        white-space: normal;
                        @include respond-to("md") {
                            min-width: 236px;
                        }
                    }
                }
            }
        }

        &.top-bar {
            padding-top: 0;
        }

        .not-eligible-top {
            color: white;
            padding: 40px 0;
            text-align: center;

            @include respond-to("md") {
                margin-bottom: 60px;
                text-align: left;
            }

            @extend .bg-cyan;

            p {
                font-size: em(19);
                margin: 0;
                padding: 10px 0;
                @extend .text-alpha-white;
                @extend %font-normal;
            }

            a {
                color: white;
                text-decoration: underline;
            }

            .content {
                padding-top: 15px;
            }

            .btn {
                font-size: em(14);
            }

            .items-fixed {
                display: block;
                text-align: center;
                float: none;

                >.btn {
                    margin: 10px;

                    @include respond-to("md") {
                        margin: 0;
                    }

                    &[class*="pull"] {
                        float: none !important;

                        @include respond-to("md") {
                            float: right !important;
                        }
                    }
                }
            }

            &.fixed {
                top: auto;
                bottom: 0;
                margin-bottom: 0;
                width: 100%;
                z-index: 5;

                &.btn {
                    width: 100%;
                }

                @include respond-to("md") {
                    top: 0;
                    bottom: auto;
                    margin-bottom: 0;
                }
            }
        }

        form#cta {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
}

.img-center {
    margin: 0 auto;
}

.show {
    display: block !important;
}

.hide {
    display: none !important;
}

.hidden-md-up {
    display: none;

    @include respond-to("md-down") {
        display: block;
    }
}

.error-bar {
    padding: 20px;
    background: $color-red;
    color: white;
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%;
    display: none;

    @include respond-to("md") {
        padding: 50px;
    }

    p {
        font-size: 20px;
        margin: 0 auto;
        max-width: 1200px;
        position: relative;

        @include respond-to("md") {
            font-size: 26px;
        }

        i {
            color: white;
            font-size: 25px;
            position: absolute;
            top: 0px;
            right: 0;
            cursor: pointer;

            @include respond-to("md") {
                top: -20px;
            }
        }
    }
}

.text-lined {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $color-gray-plain;
    line-height: 0.1em;
    margin: 50px 0 50px;

    @include respond-to("md") {
        margin: 35px 0 50px;
    }

    span {
        padding: 0 10px;
        color: $color-black-alpha;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 1.33;
        text-transform: uppercase;
        display: inline-block;
        zoom: 1;
        margin: -8px 0;
    }
}

.block {
    display: block;
}

.flex {
    @include respond-to("sm") {
        display: flex;
        flex-wrap: wrap;
    }

    @include respond-to("md") {
        display: flex;
    }
}

.flex-row {
    display: flex;
}

.row-eq-height {
    @include respond-to("md") {
        display: flex;
    }
}

.fixed {
    position: fixed;
}

.img-fit {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    height: auto;
}

.promotions-title {
    @extend %font-normal;
    font-size: 13px;
    line-height: 1.77;
    letter-spacing: 1px;
    text-align: center;
    color: rgba(8, 32, 45, 0.5);
    text-transform: uppercase;
    padding-top: 50px;
}

.side-by-side {
    .row>div[class*="col-"]:nth-child(even) .text-panel {
        @include respond-to("md") {
            padding-left: 20px;
        }
    }

    .text-panel {
        padding-top: 20px;

        @include respond-to("md") {
            padding-top: 0;
        }
    }

    >.row {
        padding: 40px 0 40px 0;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    .btn {
        min-width: 240px;
        margin: 10px 0;
    }

    .header {
        padding-top: 0;
        margin-top: 0;
    }

    .subhead {
        margin: 20px 0;
    }
}

section.accordions {
    h2{
        @extend %h2;
    }
    
    &.bg-white {
  
      .accordion-item .panel-heading {
        &.bg-gray {
          background-color: $color-gray !important;
        }
        &.bg-white {
            background-color: $color-white !important;
        }
      }
    }
  
    &.bg-gray {
  
      .accordion-item .panel-heading {
        &.bg-gray {
            background-color: $color-gray !important;
        }
        &.bg-white {
            background-color: $color-white !important;
        }
      }
    }
  }

.terms-conditions {
    p {
        color: $color-black-alpha;
        padding-bottom: 10px;

        &.title {
            letter-spacing: normal;
            color: black;
        }
    }

    ul {
        li {
            color: $color-black-alpha;

            a {
                color: $color-blue;

                &:hover {
                    color: darken($color-blue, 10);
                }
            }
        }
    }
}

.main-chat {
    position: fixed;
    bottom: 10px;
    right: 11px;
    z-index: 10;

    img {
        width: 60px;
        height: 60px;
    }
}

.v-separator {
    width: 1px;
    min-height: 115px;
    background: rgba(white, 0.1);
    margin: 20px auto;
}

.h-separator {
    width: 150px;
    height: 1px;
    background: rgba(white, 0.1);
    margin: 20px auto;
}

.slider-section .slider .slick-arrow {
    display: none !important;

    @include respond-to("lg") {
        display: block !important;
    }
}

.bcText img {
    width: 70px !important;
    height: 70px !important;
    margin-left: -10px;
    margin-top: -6px;
}

.at-resp-share-element .at-share-btn {
    background-color: transparent !important;
}

.table-bordered {
    border: 1px solid #ddd !important;
}

#rateQuoteContent table {
    thead th {
        text-align: center;
        color: white;
        background: $color-blue;
    }

    tr tr {
        border: 1px solid #ddd;
    }

    #table {
        width: 100%;
        overflow: auto;
    }
}

#member-error,
#member-error span {
    color: red;
}

#upload-avatar {
    margin: 0 auto;
    max-width: 300px !important;
    width: 300px !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;

    @include respond-to("xxs") {
        width: 280px !important;
    }
}

.show-croppie {
    padding-top: 350px;
}

input#upload[type="file"] {
    display: block;
    opacity: 0;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    cursor: pointer;
}

.swal-button {
    @extend .btn-blue;
}

#click-save {
    text-align: center;

    img {
        margin: 0 auto;

        @include respond-to("md") {
            margin: 0;
        }
    }
}

.welcome-wizard-header {
    &.navbar-fixed-top {
        background-color: #fff;
    }

    .error-bar {
        position: relative;
        padding: 20px 50px;

        p i.fa {
            top: 10px;
        }
    }

    .section-indicator {
        @include respond-to("md-down") {
            margin: 0;
            padding: 0;
        }
    }

    @include respond-to("md-down") {
        .invisible {
            display: none;
        }
    }
}

.row.scEnabledChrome .welcome-wizard-header.navbar-fixed-top {
    position: relative;
}

.welcome-wizard-body {
    padding-top: 100px;

    @include respond-to("md-down") {
        padding-top: 200px;
    }

    &.inner-step {
        @include respond-to("md-down") {
            padding-top: 40px;
        }
    }
}

.pagination-imgs-list .pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #777777;
    text-decoration: none;
    background-color: transparent;
    border: none;
    font-weight: bold;
}

.pagination-imgs-list {
    .pagination {
        a {
            cursor: pointer;

            &:hover {
                font-weight: 500;
            }
        }
    }

    .pagination> {
        a {
            cursor: pointer;
        }

        .active> {

            a,
            span,
            a:hover,
            span:hover,
            a:focus,
            span:focus {
                z-index: 3;
                color: #337ab7;
                cursor: default;
                background-color: transparent;
                border-color: transparent;
                font-weight: bold;
            }
        }

        .disabled> {
            span {
                color: #d2d2d2;
                cursor: not-allowed;
                background-color: transparent;
                border-color: transparent;

                &:hover,
                &:focus {
                    color: #d2d2d2;
                    cursor: not-allowed;
                    background-color: transparent;
                    border-color: transparent;
                }
            }

            a {
                color: #d2d2d2;
                cursor: not-allowed;
                background-color: transparent;
                border-color: transparent;

                &:hover,
                &:focus {
                    color: #d2d2d2;
                    cursor: not-allowed;
                    background-color: transparent;
                    border-color: transparent;
                }
            }
        }
    }

    .fa-chevron-left,
    .fa-chevron-right {
        margin-top: 3px;
    }
}

//fix gtm blank space
iframe[src="javascript:false"] {
    display: none;
}

.new-close {
    width: 18px;
    height: 18px;
    opacity: 0.6;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    right: 35px;

    &:hover {
        opacity: 1;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }

    &:before,
    &:after {
        position: absolute;
        content: " ";
        height: 18px;
        width: 1px;
        left: 10px;
        background-color: #333;
    }
}

.inner-container {
    padding: 0 60px;

    @include respond-to("md-down") {
        padding: 0;
    }
}

.new-header {
    text-align: center;

    h2 {
        font-size: em(32);
        font-weight: 600;
        margin: 0 auto;
        margin-bottom: 20px;

        @include respond-to("sm-down") {
            font-size: 1.6em;
            width: 90%;
        }
    }

    p {
        width: 70%;
        margin: 0 auto;

        @include respond-to("sm-down") {
            width: 90%;
        }

        &.subtitle {
            padding-top: 30px;
            font-weight: 600;
            font-size: em(18);
        }
    }
}

.blue-arrow {
    width: 26px;
    height: 26px;
    margin-left: 10px;
    vertical-align: middle;
}

.animated-4-columns {
    padding-top: 40px;

    @include respond-to("md") {
        padding-bottom: 40px;
    }

    h5 {
        font-weight: 300;
    }

    .text-lg {
        font-size: 20px;
    }

    .card-animated {
        height: 160px;
        border-radius: 7px 7px 7px 7px;
        margin-top: 30px;
        margin-bottom: 100px;
        padding: 2em;
        padding-bottom: 3em;
        background: $color-light-blue;
        position: relative;
        opacity: 0;

        &.card-animation {
            animation: fadein 1.3s forwards;
        }

        @include respond-to("sm") {
            height: 180px;
            margin-bottom: 70px;
        }

        @include respond-to("md") {
            height: 295px;
            margin-bottom: 30px;
        }

        @include respond-to("lg") {
            height: 322px;
            margin-bottom: 50px;
        }

        .icon-round {
            position: relative;
            margin-top: -6em;
            margin-bottom: 1em;
            margin-left: auto;
            margin-right: auto;
            border-radius: 50%;
            background: $color-aquamarine;
            color: white;
            padding: 1em;
            width: 116px;
            height: 116px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include respond-to("md") {
                height: 140px;
                width: 140px;
            }

            @include respond-to("lg") {
                height: 185px;
                width: 185px;
            }

            img {
                max-width: 80%;
                height: auto;

                @include respond-to("md") {
                    width: auto;
                }

                @include respond-to("lg") {
                    max-width: 100%;
                }
            }
        }

        p {
            color: white;
        }

        @keyframes fadein {
            0% {
                transform: translateY(90px)
            }

            100% {
                transform: translateY(0)
            }

            from {
                opacity: 0
            }

            to {
                opacity: 1
            }
        }
    }
}

.animated-center-content {
    background: $color-blue;
    color: white;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;

    .btn-orange {
        padding: 1em 2.5em;
        font-size: 14px;

        @include respond-to("md") {
            font-size: 16px;
        }
    }

    .btn-orange:hover {
        background: #007A6A;
        color: white;
    }

    .fa {
        font-size: 20px;

        @include respond-to("md") {
            font-size: 24px;
        }
    }

    .share {
        display: inline-block;
    }

    .spacer {
        padding-top: 1.5em;
    }

    span {
        color: white;
    }

    .small-text {
        font-size: .8em;

        @include respond-to("sm") {
            font-size: .9em;
        }

        @include respond-to("md") {
            font-size: 1em;
        }
    }

    .title1 {
        font-size: 0.9em;
        font-weight: 400;

        @include respond-to("sm") {
            font-size: 1.3em;
        }

        @include respond-to("md") {
            font-size: 1.4em;
        }
    }

    .title2 {
        font-size: 1.3em;
        font-weight: 700;

        @include respond-to("sm") {
            font-size: 2.0em;
        }

        @include respond-to("md") {
            font-size: 2.2em;
        }
    }

    @keyframes slideup {
        from {
            top: 20px
        }

        to {
            top: 0px;
        }
    }

    @keyframes slidedown {
        from {
            top: -20px
        }

        to {
            top: 0px;
        }
    }
}

.blue-centered-content {
    background: #002c44;
    color: #ADB8BF;
    font-size: 0.65em;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;

    @include respond-to("sm") {
        font-size: 0.66em;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    @include respond-to("md") {
        font-size: 0.75em;
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.blue-centered-promotional {
    background: $color-blue;
    color: white;
    padding: 64px 0px 64px 0px;
    text-align: center;

    .title {
        font-size: 36px;
        font-weight: bold;
        line-height: 48px;
        padding-bottom: 24px;

        @include respond-to("md-down") {
            font-size: 28px;
            line-height: 32px;
            padding-bottom: 16px;
        }
    }

    .small-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        @include respond-to("sm") {
            font-size: 18px;
            height: 128px;
            line-height: 32px;
        }

        @include respond-to("md") {
            font-size: 18px;
            height: 96px;
            line-height: 32px;
        }

        .container {
            padding: 0px;
            width: 846px;

            @include respond-to("md-down") {
                width: auto;
            }
        }
    }

    .add-top {
        padding-top: 32px;
    }

    .add-bottom {
        @include respond-to("md-down") {
            padding-bottom: 48px;
        }
    }

    .card {
        display: inline-block;
        padding-bottom: 24px;
        vertical-align: top;

        .title1 {
            font-size: 54px;
            font-weight: bold;
            height: 78px;
        }

        .title2 {
            font-size: 32px;
            font-weight: bold;
            line-height: 38px;
            padding-bottom: 24px;

            @include respond-to("md-down") {
                padding-bottom: 12px;
            }
        }

        .content {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;

            @include respond-to("md-down") {
                font-size: 16px;
                line-height: 24px;
            }
        }

        @include respond-to("sm") {
            padding: 30px 0px 0px 0px;
            width: 362px;
        }

        @include respond-to("md") {
            padding: 30px 0px 0px 0px;
            width: 362px;
        }
    }

    .card:not(:last-child) {
        padding-right: 30px;

        @include respond-to("md-down") {
            padding-right: 0px;
        }
    }

    @include respond-to("md-down") {
        overflow: hidden;
        padding: 16px 0px 16px 0px;
        text-align: left;
    }

    a.arrow-link {
        color: white;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.035em;
    }

    a.arrow-link:focus,
    a.arrow-link:hover {
        text-decoration: none;
    }

    a.arrow-link::after {
        content: " ";
        color: #81939F;
        width: 26px;
        height: 26px;
        display: inline-block;
        vertical-align: bottom;
        margin-left: 10px;
    }

    a.arrow-link::after {
        background: url("../images/arrow-right-white-hover.png") no-repeat;
    }
}

.centered-cta-banner {
    background: $color-blue;
    padding: 48px 0px 48px 0px;
    text-align: center;

    .title {
        color: #F5F4F1;
        font-size: 36px;
        font-weight: bold;
        height: 62px;
        line-height: 48px;

        @include respond-to("md-down") {
            font-size: 24px;
            height: 56px;
            line-height: 32px;
        }
    }

    .btn {
        color: $color-blue;
        fill: white;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        margin-top: 32px;
        min-width: 198px;

        @include respond-to("md-down") {
            margin: 0px auto;
        }
    }
}

.center-headline {
    background: $color-blue;
    color: white;
    padding: 68px 0px 68px 0px;
    text-align: center;

    @include respond-to("md-down") {
        padding: 32px 0px 77px 0px;
        text-align: left;
    }

    .title {
        font-size: 48px;
        font-weight: bold;
        line-height: 60px;

        @include respond-to("md-down") {
            font-size: 32px;
            line-height: 38px;
        }

        .container {
            padding: 0px;
            width: 1040px;

            @include respond-to("md-down") {
                margin: 0px;
                width: auto;
            }
        }
    }

    .content {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        height: 73px;
        line-height: 32px;
        margin-top: 24px;

        @include respond-to("md-down") {
            font-size: 16px;
            height: 72px;
            line-height: 24px;
            margin-top: 12px;
        }

        .container {
            padding: 0px;
            width: 547px;

            @include respond-to("md-down") {
                margin: 0px;
            }

            @include respond-to("sm-down") {
                margin: 0px;
                width: 279px;
            }
        }
    }

    .btn {
        color: $color-blue;
        fill: white;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        margin-top: 32px;
        width: 227px;

        @include respond-to("md-down") {
            margin-top: 24px;
        }
    }
}

.two-column-text-image {
    background: #F5F4F1;
    padding: 96px 0px 48px 0px;

    @include respond-to("md-down") {
        display: none !important;
    }

    .container {
        padding: 0px;
    }

    .col-xs-6 {
        padding: 0px;
    }

    .title {
        font-size: 36px;
        font-weight: bold;
        height: 96px;
        line-height: 48px;
    }

    .content {
        color: black;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        height: 96px;
        line-height: 32px;
        margin: 18px 0px 18px 0px;
        max-width: 460px;
    }

    img {
        border: 0px;
        padding-left: 100px;
        width: 100%;
    }

    a.arrow-link {
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.08em;
    }

    a.arrow-link:focus,
    a.arrow-link:hover {
        text-decoration: none;
    }

    a.arrow-link::after {
        content: " ";
        color: #81939F;
        width: 26px;
        height: 26px;
        display: inline-block;
        vertical-align: bottom;
        margin-left: 10px;
    }

    a.arrow-link::after {
        background: url('../images/new-icons/arrow-right-blue-bg-hover.svg') no-repeat;
    }
}

.two-column-text-image-mobile {
    background: #F5F4F1;
    display: none !important;
    padding: 48px 0px 48px 0px;
    text-align: left;

    @include respond-to("md-down") {
        display: block !important;
    }

    .title {
        font-size: 28px;
        font-weight: bold;
        line-height: 32px;
    }

    .content {
        color: black;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 12px 0px 24px 0px;
        max-width: 460px;
    }

    img {
        border: 0px;
        margin-top: 32px;
        width: 100%;
    }

    a.arrow-link {
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.08em;
    }

    a.arrow-link:focus,
    a.arrow-link:hover {
        text-decoration: none;
    }

    a.arrow-link::after {
        content: " ";
        color: #81939F;
        width: 26px;
        height: 26px;
        display: inline-block;
        vertical-align: bottom;
        margin-left: 10px;
    }

    a.arrow-link::after {
        background: url('../images/new-icons/arrow-right-blue-bg-hover.svg') no-repeat;
    }
}

// hero loan debt section
.loan-hero {
    .hero-banner-desktop {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;

        .container {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            height: 100%;
        }

        .black-bg {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 40%, rgba(0, 0, 0, 0.1) 100%);
            width: 100%;
            height: auto;
            padding-top: 5em;
            padding-bottom: 3em;

            @include respond-to("md-down") {
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.85) 10%, rgba(0, 0, 0, 0.6) 100%);
            }

            @include respond-to("sm-down") {
                padding-top: 4.5em;
                padding-bottom: 2.7em;
            }

            @media (min-width:768px) and (max-width:991px) {
                padding-top: 4em;
                padding-bottom: 2.4em;
            }

            .hero-loan-content {
                color: #fff;
                padding: 0px;

                p {
                    font-size: 1em;

                    @include respond-to("sm-down") {
                        font-size: 0.8em;
                    }

                    @media (min-width:768px) and (max-width:991px) {
                        font-size: 0.9em;
                    }
                }

                h1 {
                    font-size: 2.8em;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 700;
                    color: #fff;
                    line-height: 1.3;
                    margin-top: 0em;
                    margin-bottom: 16px;

                    @include respond-to("xs-down") {
                        font-size: 1.44em !important;
                    }

                    @include respond-to("sm-down") {
                        font-size: 1.8em;
                    }

                    @media (min-width:768px) and (max-width:991px) {
                        font-size: 2.524em;
                    }
                }

                h5 {
                    font-size: 1.4em;
                    font-weight: 400;
                    margin: 0;

                    @include respond-to("sm-down") {
                        font-size: 0.88em;
                    }

                    @media (min-width:768px) and (max-width:991px) {
                        font-size: 1.26em;
                    }
                }

                .btn-loan {
                    font-size: 1.2em;
                    padding: 1em 2em;
                    text-align: center;
                    margin-bottom: 1em;
                    color: #005daa !important;
                    border-color: #FFF;

                    @include respond-to("sm-down") {
                        padding: 15.36px 30.72px;
                        margin-bottom: 15.36px;
                        font-size: 15.36px;
                        line-height: 15.36px;
                    }

                    @media (min-width:768px) and (max-width:991px) {
                        padding: 17.28px 34.56px;
                        margin-bottom: 17.58px;
                        font-size: 17.28px;
                        line-height: 17.28px;
                    }

                    &:hover {
                        background: rgb(230, 228, 220);
                    }

                    &::after {
                        display: inline-block;
                        content: " ";
                        background: url(../images/new-icons/arrow-down-blue.svg) no-repeat;
                        margin-left: 15px;
                        width: 15px;
                        height: 8px;
                    }

                    &:focus {
                        background: rgb(255, 255, 255);
                    }
                }

                .color-d {
                    font-size: 0.75em;
                    padding-bottom: 0.2em;
                    padding-top: 0.2em;
                    color: #adb8bf;

                    p {
                        font-size: 12px;

                        @include respond-to("sm-down") {
                            font-size: 9.6px;
                        }

                        @media (min-width:768px) and (max-width:991px) {
                            font-size: 10.8px;
                        }
                    }
                }
            }
        }

    }
}

section.c-088 {
    padding: 23px 0 32px;

    @include respond-to("md") {
        padding: 40px 0;
    }

    .text-container {
        max-width: 553px;

        h1 {
            font-weight: 700;
            font-size: 48px;
            color: #fff;
            line-height: 60px;
            font-family: 'Open Sans', sans-serif;

            @include respond-to("md-down") {
                font-size: 32px;
                line-height: 38px;
                letter-spacing: -2px;
                padding-top: 0px;
            }
        }

        span {
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 32px;

            @include respond-to("md-down") {
                font-size: 16px;
                line-height: 24px;
            }
        }

        .button {
            padding-top: 32px;

            a {
                font-size: 16px;
                color: $color-blue;
                line-height: 24px;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
            }

            @include respond-to("md-down") {
                padding-bottom: 32px;
            }

            @include respond-to("md-down") {
                padding-top: 24px;
            }
        }
        
    }
    .img-fit{
        @include respond-to("md"){
            max-height: 300px;
        }
    }
}
.partner-logo{
    max-height: 60px;
    margin-top: 40px;
    @include respond-to("md-down") {
        margin-top: 0px;
        margin-bottom: 32px;
    }
}

section.enroll-plans {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 40px 0;
    max-width: 752px;
    text-align: center;

    .plans-container {
        width: 100%;

        @include respond-to("sm-down") {
            width: 80%;
        }

        h4 {
            color: $color-blue;
            font-weight: 700;
            font-size: 36px;
            line-height: 48px;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: -0.04em;

            @include respond-to("sm-down") {
                font-size: 20px;
                line-height: 26px;
            }
        }

        .form-plans {
            max-width: 530px;
            margin: auto;

            h6 {
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.03em;
                color: #37424A;
                padding: 14px 0;
            }

            input[type=radio] {
                height: 20px;
                width: 20px;
                vertical-align: middle;
            }

            label {
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                color: $color-blue;
                padding-left: 16px;
                vertical-align: -webkit-baseline-middle;
            }

            .mandatory-radios {
                padding: 32px 0;
                display: flex;

                .input-group {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    @include respond-to("sm") {
                        &:first-of-type {
                            justify-content: end;
                            padding-right: 20px;
                        }
                    }
                }
            }

            .optional-radios {
                padding: 32px 0;
                display: flex;

                .input-group {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }
            }

            @include respond-to("sm-down") {
                h6 {
                    font-size: 18px;
                    line-height: 24px;
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                    margin-top: 2px;
                }

                label {
                    font-size: 16px;
                    line-height: 24px;
                }

                .mandatory-radios,
                .optional-radios {
                    flex-direction: column;
                    padding: 0;
                }

                .input-group {
                    padding: 24px 0 0 0;

                    div {
                        min-width: 165px;
                        text-align: left;
                    }
                }
            }
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 32px;
            text-align: center;
            color: #111111;

            a {
                color: $color-blue;
                font-weight: 700;
                text-decoration: underline;
            }

            @include respond-to("sm-down") {
                font-size: 16px;
                line-height: 24px;
                padding-top: 40px;
            }
        }

        div.button {
            .btn {
                padding: 13px 30px;
                margin: 32px;
                font-size: 16px;
                line-height: 24px;

                @include respond-to("sm-down") {
                    margin: 24px;
                }
            }

            .cancel {
                color: $color-blue;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                text-decoration-line: underline;
            }
        }
    }
}

section.c-001-4-column-text {

    @include respond-to("md-down") {
        padding-top: 32px;
        padding-bottom: 0px;
    }
    &.bg-blue{
        .title-4-column {
            h4{
                color: $color-white;
            }
        }
        .text-4-column {
            h6{
                color: $color-white;
            }
            p{
                color: $color-white;
            }
        }
    }

    .title-4-column {
        text-align: center;
        padding-bottom: 76px;

        @include respond-to("md-down") {
            text-align: left;
            padding-bottom: 40px;
        }

        h4 {
            color: $color-blue;
            font-family: $font-family;
            font-size: 36px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: -0.04em;

            @include respond-to("md-down") {
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.05em;
            }
            &.text-white{
                color: $color-white;
            }
            &.text-alto-dark{
                color: $color-alto-dark;
            }
        }
    }

    .text-4-column {

        h6 {
            color: $color-alto-dark;
            font-family: $font-family;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.03em;
            min-height: 64px;
            margin: 0;

            @include respond-to("md-down") {
                font-size: 18px;
                line-height: 24px;
                min-height: auto;
            }
            &.text-white{
                color: $color-white;
            }
            &.text-blue{
                color: $color-blue;
            }
        }

        hr.solid {
            border-top: 1px solid #D9D5CB;
            margin: 16px 0;

            @include respond-to("md-down") {
                margin: 12px 0;
            }
        }

        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: $color-alto-dark;
            padding: 0;
            margin: 0;
            &.text-white{
                color: $color-white;
            }
            &.text-blue{
                color: $color-blue;
            }

            @include respond-to("md-down") {
                font-size: 16px;
                line-height: 24px;
                padding-bottom: 48px;
            }
        }
    }
}

.container{
    .col-xs-12.text-center{
        h1{
            @extend %h1;
        }
    }
}

.pb-0{
    padding-bottom: 0px !important;
}

section#overview{
    h2{
        @extend %h2;
    }
    h3{
        @extend %h3;

    }
}

//Recaptcha V3 Styles
.grecaptcha-badge {
    visibility: hidden;
}

.recapv3 {
    color: #555;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    label {
        font-size: 0.9em;
        padding-left: 10px;
        font-weight: normal;
        margin-bottom: 0px;
        a {
            color: #221199;
            &::hover {
                text-decoration: underline
            }
        }
    }
    img {
        width: 28px;
        float: left;
    }
}
