$breakpoints: ( 'xxs': (max-width: 320px), 
'xs': (min-width: 435px), 
'xs-down': (max-width: 434px), 
'sm': (min-width: 768px), 
'sm-down': (max-width: 767px), 
'md': (min-width: 992px), 
'md-down': (max-width: 991px), 
'lg': (min-width: 1200px),
'lg-down': (max-width: 1199px),
'xl-lg': (max-width: 1500px),
'xl-lg-down': (max-width: 1499px));
@mixin respond-to($breakpoint) {
    $raw-query: map-get($breakpoints, $breakpoint);
    @if $raw-query {
        $query: if( type-of($raw-query)=='string', unquote($raw-query), inspect($raw-query));
        @media #{$query} {
            @content;
        }
    }
    @else {
        @error 'No value found for `#{$breakpoint}`. '+'Please make sure it is defined in `$breakpoints` map.';
    }
}