.btn {
    @extend %btn;
    &-large {
        width: 100%;
        margin-bottom: 20px;
    }
    &-modal {
        padding: 11px 30px;
        font-size: em(14);
        font-weight: 600;
        min-width: 150px;
        max-width: 200px;
        text-transform: uppercase;
        margin: 25px 0;
        &:hover {
            padding: 11px 30px;
            a {
                color: black;
            }
        }
    }
    &-secondary {
        min-width: 104px;
        max-width: 204px;
        width: 204px;
        font-size: em(18);
        margin: 40px 0;
        &:hover {
            a {
                color: black;
            }
        }
        a {
            color: black;
        }
    }
    &-orange {
        color: $color-white;
        background: $color-orange;
        &:hover,
        &:focus {
            background: $color-cta-tertiary-roll;
            color: $color-white;
        }
        &.calcas {
            padding: 8px 30px;
            font-size: 16px;
        }
    }
    &-green {
        color: $color-white;
        background: $color-green;
        &:hover,
        &:focus {
            background: $color-cta-secondary-roll;
            color: $color-white;
        }
    }
    &-blue {
        color: $color-white !important;
        background-color: $color-blue;
        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-cta-primary-roll;
        }
        a {
            color: $color-white;
        }
    }
    &-cyan {
        color: $color-white;
        background-color: $color-cyan;
        &:hover,
        &:focus {
            background-color: darken($color-cyan, 5%);
            color: $color-white;
            a {
                color: $color-white;
            }
        }
        a {
            color: $color-white;
        }
    }
    &-gray {
        color: $color-alto-dark;
        background-color: $color-primary-beige;
        &:hover,
        &:focus {
            background-color: $color-primary-beige-medium;
            color: $color-alto-dark;
            a {
                color: $color-alto-dark;
            }
        }
        a {
            color: $color-alto-dark;
        }
    }
    &-dark-blue {
        color: $color-white;
        background: $color-footer;
        &:hover,
        &:focus {
            background: darken($color-footer, 5%);
            color: $color-white;
            a {
                color: $color-white;
            }
        }
        a {
            color: $color-white;
        }
    }
    &-aquamarine {
        color: $color-white;
        background: $color-aquamarine;
        &:hover,
        &:focus {
            background: darken($color-aquamarine, 5%);
            color: $color-white;
            a {
                color: $color-white;
            }
        }
        a {
            color: $color-white;
        }
    }
    &-bordered-white {
        color: $color-white;
        background: transparent;
        border-color: $color-white;
        &:hover,
        &:focus {
            background: $color-white;
            color: black;
            a {
                color: black;
            }
        }
        a {
            color: $color-white;
        }
    }
    &-white {
        background: $color-white;
        color: black;
        &:hover,
        &:focus {
            background: $color-footer;
            color: $color-white !important;
            a {
                color: $color-white !important;
            }
        }
        a {
            color: $color-white;
        }
    }
    &-bordered-blue {
        color: $color-footer;
        background: transparent;
        border-color: $color-footer;
        &:hover,
        &.active {
            background: $color-footer;
            color: $color-white;
        }
    }
    &-filters {
        padding: 10px 20px;
        margin: 10px 5px;
        @extend %font-normal;
        font-size: 12px;
        font-weight: bold;
        &:hover,
        &:focus,
        &.active {
            font-size: 12px;
            padding: 10px 20px;
        }
    } //filters
    &.disabled {
        @extend %disabled;
        a {
            @extend %disabled;
        }
    }
} //btn

.language-toggle {
    text-align: center;

    .btn-group {
        margin: 25px 0px;
        margin-bottom: 0;

        .btn {
            padding: 10px 20px;
            font-size: 10px;
            border: 1px solid $color-blue;
        }

        @include respond-to('md') {
            .btn {
                font-size: 12px;
            }
        }

        .btn-toggle-accent {
            background-color: $color-blue;
            color: white;

            &:hover,
            &:focus {
                background: white;
                color: $color-dark-gray
            }
        }
    }
}


.btn {
    &-new {
        @extend %new-design;

        &.cta {
            background-color: $color-white !important;
            max-width: 340px;
            width: 90%;
            min-width: auto;

            &:hover {
                background-color: $color-primary-beige !important;
            }

            @include respond-to("sm-down") {
                line-height: 18px;
            }

            @include respond-to("sm") {
                box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.2);
                padding: 14px 30px;

                &:hover {
                    padding: 14px 30px;
                }
            }

            &.btn-aquamarine {
                color: $color-aquamarine !important;
            }

            &.btn-blue {
                color: $color-blue !important;
            }

            &.btn-cyan {
                color: $color-cyan !important;
            }

            &.btn-orange {
                color: $color-orange !important;
            }
        }
    }

    &.btn-outline-default {
        border: 2px solid $color-alto-medium;
        color: $color-alto-medium;
        background-color: $color-white;
        box-shadow: none;
        letter-spacing: -0.5px;
        margin-bottom: 20px;

        @include respond-to("md") {
            width: 328px;
        }

        @include respond-to("sm-down") {
            font-size: 16px;
            display: block;
            width: 100%;
        }

        &.accent {
            color: $color-white;
            background-color: $color-blue;
            border: 2px solid $color-blue;
        }
    }
}
