#middle-menu{
    @include respond-to("md"){
        padding:35px;
    }
    ul {
        text-align: center;
        list-style-type: none;
        margin:0;
        padding:0;
        @extend %flex-center-items;

        li {
            margin:0;
            min-height: 20px;
            line-height: 20px;
            margin-right: 40px;
            text-align: center;
            
            &:last-of-type {
                margin-right: 0 !important;
            }

            a {
                @extend %transition;

                color: $color-blue;
                line-height: 90px;
                text-decoration: none;
                @include respond-to('md'){
                    line-height: 20px;
                }
            

                &:hover {
                    color: lighten($color-blue, 10%);
                }
            }
        } //li
    }
    .items-fixed{
        display: none;
    }
    &.fixed{
        position: fixed;
        z-index: 10;
        bottom:0;
        left: 0;
        width: 100%;
        background: white;
        padding: 0 10px;
        padding-bottom: 30px;
        @extend %box-shadow-bottom;
        @include respond-to('md'){
            top: 0;
            bottom: auto;
            padding-bottom: 0;
        }
        > .container{
            padding-left: 0;
            padding-right: 0;
        }
        ul{
            float:left;
            width: 54%;
            &.single{
                width: 63%;
            }
            @include respond-to('md'){
                margin:0 20px;
            }
            @include respond-to('lg'){
                margin:0 20px;
            }

            li{
                min-height: 90px;
                line-height: 90px;
                @include respond-to('md'){
                    margin-right: 20px;
                }
                @include respond-to('lg'){
                    margin-right: 40px;
                }
                a{
                    @include respond-to('md'){
                        font-size: em(14);
                    }
                    @include respond-to('lg'){
                        font-size: em(16);
                    }
                }
            }//li
            
        }
        .items-fixed{
            display: block;
            text-align: center;
            @include respond-to('md'){
                float: left;
            }
            &.buttons{
                padding-top: 20px;
                float: none;
                @include respond-to('md'){
                    float: right;
                    padding-bottom: 20px;

                }
                @include respond-to('md'){
                    float: right;    
                }
                .btn{
                    @include respond-to('xxs'){
                        padding:15px 19px;  
                    } 
                    &.pull-right{
                        float: none !important;
                        @include respond-to('md'){
                            float: right;
                        }
                    }  
                }//btn
            }//buttons
            &.text{
                width:100%;
                //padding: 10px;
                text-align: center;
                @include respond-to('md'){
                    padding-top: 15px;
                    padding-bottom: 15px;
                    width:30%;
                    text-align: left;
                }
                @include respond-to('lg'){
                    padding-top: 25px;
                    width:26%;
                }
                p{
                    margin:0;
                    padding: 20px 0;
                    font-size: 13px;
                    color:$color-black-alpha;
                    text-align: center;
                    text-indent: -10px;
                    margin-left: 10px;
                    @include respond-to('md'){
                        text-align: left;
                        padding: 0;
                    }
                    i{
                        padding:3px 0 5px 0;
                        //float: left;
                        color:$color-aquamarine;
                        //width: 15px;
                        font-size: 15px;
                        margin-right: 5px;
                    }
                    span{
                        //float: left;
                        //width: auto;
                    }
                }//p
            }//text

            
            &.title{
                padding-top: 15px;
                text-align: center;
                float: none;
                width: 15%;    
                @include respond-to('md'){
                    font-size: em(14);
                    padding-top: 35px;
                    text-align: left;
                    float: left;
                }
                @include respond-to('lg'){
                    font-size: em(16);
                    padding-top: 29px;
                }
                p{
                    @extend %ellipsis;
                    width: 100%;    
                }
            } 
            .btn{
                font-size: em(13);
                @include respond-to('md'){
                    padding: 15px 20px;
                }
                @include respond-to('lg'){
                    max-width: 150px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }//items-fixed
        
          
    }//menu-fix



    &.comp-life-sticky{
        padding: 0;
            //comp-life-options
        &.sticky{
            .options{
                display: none !important;
       
            }
        }  
        &.sticky.fixed{
            .options{
                display: flex !important;
                @include respond-to('md-down'){
                    display: none !important;
                }
            }
        }
        @include respond-to('md-down'){
            padding: 0 10px 30px;
        }
    }

}//middle-menu

