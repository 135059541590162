footer {
    .banner-container {
      padding-top: 40px;
  
      @include respond-to('md-down') {
        padding-top: 20px;
      }
  
      .banner-footer {
        background-color: $color-gray;
        max-width: 1000px;
        display: flex;
        margin: auto;
        padding: 30px 60px;
        justify-content: space-between;
  
        @include respond-to('md-down') {
          flex-direction: column;
          padding: 22px;
        }
  
        .footer-link {
          a {
            color: #134B86;
            font-size: 20px;
            font-weight: 700;
  
            @include respond-to('md-down') {
              font-size: 16px;
              padding: 8px 0px;
            }
  
            &:after {
              content: " ";
              width: 26px;
              height: 26px;
              display: inline-block;
              vertical-align: top;
  
              margin-left: 10px;
              background-image: url(../images/new-icons/arrow-right-blue-new.svg);
              background-size: contain;
              background-repeat: no-repeat;
  
              @include respond-to('md-down') {
                width: 18px;
                height: 18px;
                margin-top: 4px;
              }
            }
          }
        }
      }
  
    }
  
    background: $color-footer;
  
    .container {
      padding-top: 25px;
  
      @include respond-to('sm') {
        padding-top: 50px;
        padding-bottom: 20px;
      }
    }
  
    p.header {
      font-size: em(18);
      line-height: em(23);
      padding: 20px 0 7px 0;
      color: white;
      font-weight: bold;
    }
  
    .row {
      @include respond-to("md") {
        display: flex;
      }
  
      .col-md-3 {
        @include respond-to("md") {
          align-self: flex-end;
        }
      }
    }
  
    .footer-menu {
      display: flex;
      padding-bottom: 80px;
  
      &.row {
        padding: 0;
        margin: 0;
      }
  
      .divider {
        padding: 10px 0;
      }
  
      .col {
        width: 20%;
        padding: 0 10px;
  
        &:first-of-type {
          padding-left: 0;
        }
  
        &:last-of-type {
          padding-right: 0;
        }
      }
  
      .c-soon {
        color: rgba(255, 255, 255, 0.5);
      }
  
      &.footer-new-design {
        justify-content: space-between;
  
        @include respond-to("md-down") {
          flex-direction: column;
  
          .col {
            width: 100%;
            padding-left: 12px;
  
            a {
              margin-left: 12px;
              font-size: 14px;
              color: white;
            }
  
            .header {
              padding-top: 15px;
              padding-bottom: 10px;
              margin-left: 0px;
              font-size: 16px;
  
              a {
                margin-left: 0px;
              }
            }
  
            .share {
              a {
  
                &:not(:last-child) {
                  margin-right: 14px;
                }
  
                &:first-child {
                  margin-left: 0px;
                }
  
                em {
                  &.fa-1-5x {
                    font-size: 1.5em;
                  }
                }
              }
            }
  
  
            .col-xs-6.column-left {
              padding-left: 0px;
  
              img {
                @include respond-to("sm-down") {
                  width: 80%;
                }
              }
            }
  
            .col-xs-6.column-right {
              padding: 0px;
  
              .copy {
                padding: 0px;
                margin: 0px;
                font-style: italic;
                text-transform: uppercase;
  
                a {
                  margin-left: 0px;
                }
              }
  
              @media (min-width:768px) and (max-width:991px) {
                padding-top: 18px;
              }
            }
  
            .footer-logo {
              margin-left: 0px;
            }
  
            .gtm-social-connect {
              margin-top: 20px;
            }
          }
        }
  
        .col-xs-6.column-left,
        .col-xs-6.column-right {
          padding: 0px;
        }
  
        a {
          &:hover {
            text-decoration: underline;
          }
        }
  
        .footer-logo {
          margin-top: 0px;
          margin-bottom: 38px;
        }
  
        .header {
          padding-top: 0px;
          margin-top: 0px;
  
          a {
            line-height: 1.4375em;
            padding: 0;
            color: white;
            cursor: pointer;
          }
        }
  
        .copy {
          color: white;
          margin-top: 18px;
  
          @include respond-to("sm-down") {
            font-size: 12px;
  
            a {
              font-size: 12px;
            }
          }
        }
  
        .col {
          a {
            color: white;
  
            &:visited {
              color: white;
            }
  
          }
  
          &.last-col {
            p.header {
  
              @include respond-to("md-down") {
                padding-bottom: 0px;
  
                &:not(:first-child) {
                  padding-top: 5px;
                }
  
                a {
                  font-size: 16px;
                }
  
              }
  
            }
          }
  
          .share {
            a {
              em {
                &.fa-1-5x {
                  font-size: 1.5em;
                }
              }
            }
          }
        }
      }
    }
  
    .col-left.new-col-left {
      width: 100% !important;
      text-align: center;
  
  
      .bottom-menu {
        padding-top: 50px;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
  
        a {
          color: white;
          width: auto;
          font-size: 16px;
          line-height: 16px;
  
          @include respond-to("md-down") {
            font-size: 14px;
          }
        }
  
        span {
          color: white;
          padding: 0 20px;
  
          @include respond-to("md-down") {
            font-size: 14px;
          }
        }
  
      }
  
      ul.sub-menu {
        padding-top: 50px;
        display: inline-block;
  
        @include respond-to("md-down") {
          padding-top: 30px;
          margin: 0px;
          padding-bottom: 30px;
  
          li:nth-child(4n) {
            padding-left: 14px;
          }
  
          :first-child {
            padding-left: 14px;
          }
        }
  
        li {
          &:not(:last-of-type) {
            border-right: 1px solid white;
          }
  
          a {
            color: white !important;
          }
  
          ;
        }
      }
  
      p {
        width: 100% !important;
        padding-left: 0px;
        margin-top: 0px;
        color: white;
  
        @include respond-to("md-down") {
          padding-left: 12px;
        }
      }
    }
  
    //footer
    a {
      display: block;
      width: 100%;
      padding: 5px 0;
      color: rgba(255, 255, 255, 0.5);
  
      &:hover,
      &:focus,
      &:visited {
        color: darken(white, 10);
        padding: 5px 0;
      }
    }
  
    .share {
      a {
        color: white;
        display: inline-block;
        margin-right: 10px;
        width: auto;
  
        &:last-child {
          margin-right: 0;
        }
  
        .svg-inline--fa {
          color: white;
          font-size: em(23);
  
          &:last-child {
            margin-left: 0;
          }
        }
      }
  
      //a
    }
  
    //share
    .footer-logo {
      width: 149px;
      display: block;
      margin: 45px 0;
  
      @include respond-to ('md') {
        margin: 30px 0;
      }
    }
  
    .sub-menu {
      margin: 0 0 44px 0;
      padding: 0;
      list-style-type: none;
  
      @include respond-to('sm') {
        margin: 0 0 40px 0;
      }
  
      li {
        padding: 0 20px;
        margin-bottom: 15px;
        float: left;
        border-right: 1px solid white;
  
        &:nth-child(4n) {
          padding-left: 0;
        }
  
        &:first-child {
          padding-left: 0;
        }
  
        &:last-child {
          border: none;
        }
  
        a {
          color: white;
          font-size: em(13);
          line-height: em(13);
          padding: 0;
        }
  
        @include respond-to('xxs') {
          padding: 0 15px;
        }
  
        @include respond-to('sm') {
          margin-bottom: 0;
  
          &:nth-child(3n+3) {
            border-right: 1px solid white;
          }
  
          &:nth-child(3n+1) {
            clear: initial;
            padding: 0 20px;
          }
  
          &:first-child {
            padding-left: 0;
          }
  
          &:last-child {
            border: none;
          }
        }
      }
  
      //li
      &:after {
        @extend .clearfix;
      }
    }
  
    .version-number {
      span {
        color: white !important;
      }
    }
  
    //sub-menu
    .copy,
    .address {
      margin-top: 40px;
      font-size: em(12);
      line-height: em(25);
      color: rgba(255, 255, 255, 0.5);
    }
  
    .copy {
      @include respond-to ('md') {
        margin-top: 40px;
      }
    }
  
    .address {
      margin-top: 10px;
      font-size: em(13);
      line-height: em(22);
  
      p {
        a {
          padding: 0;
        }
      }
    }
  
    #accordion-footer {
      .panel-default {
        &.panel {
          background: none;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
  
        border:none;
  
        >.panel-heading {
          color: white;
          background: none;
          border: none;
          padding: 10px 0;
  
          h4.panel-title {
            >a {
              font-size: em(18);
  
              &:hover,
              &:focus {
                text-decoration: none;
                border: none;
                outline: none;
              }
  
              .svg-inline--fa {
                font-size: 13px;
              }
  
              >.fa-plus {
                display: none;
              }
  
              >.fa-minus {
                display: block;
              }
  
              &.collapsed {
                >.fa-plus {
                  display: block;
                }
  
                >.fa-minus {
                  display: none;
                }
              }
            }
  
            //a
          }
  
          //panel-title
          +.panel-collapse>.panel-body {
            border: none;
            padding: 0;
            padding-bottom: 20px;
  
            a {
              font-size: em(16);
            }
          }
  
          //panel-body
        }
  
        //panel-heading
      }
  
      //panel-default
    }
  
    //accordion-footer
  
    .new-design,
    div.new-design~section {
      background: $color-cta-primary-roll;
  
      @include respond-to('lg') {
        .container {
          width: 1170px;
        }
      }
  
      div.col {
        a {
          line-height: 2;
        }
      }
  
      .col-left {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
  
        p {
          width: 90%;
        }
  
        @include respond-to('sm') {
          width: 80%;
        }
  
  
      }
  
      .col-right {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
  
        img {
          width: 155px;
        }
  
        @include respond-to('sm') {
          width: 20%;
          padding-right: 0;
          padding-left: 0;
        }
      }
  
      .address {
  
        a,
        p {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.5);
          line-height: 20px;
        }
      }
  
      .footer-logo {
        width: 156px;
        margin-bottom: 10px;
  
        @include respond-to('sm') {
          width: 207px;
          margin-bottom: 15px;
        }
      }
  
      .sub-menu {
        @include respond-to('sm') {
          margin: 0 0 20px 0;
        }
  
        li {
          padding: 0 14px;
  
          &:first-child {
            padding-left: 0;
          }
  
          &:nth-child(4n) {
            padding-left: 0;
          }
  
          @include respond-to('sm') {
            padding: 0 20px;
  
            &:nth-child(4n) {
              padding-left: 20px;
            }
          }
  
          a {
            color: white;
            font-size: 14px;
  
            @include respond-to('sm') {
              font-size: 16px;
              line-height: 16px;
            }
          }
        }
  
  
      }
  
      .copy {
        font-size: 14px;
        line-height: 18px;
        color: #9DA8B3;
  
        @include respond-to ('md') {
          margin-top: 30px;
        }
      }
  
      .card-title {
        color: $color-alto-light;
        font-size: 10px;
        line-height: 12px;
        font-weight: bold;
      }
  
      a.arrow-link {
        font-size: 16px;
        line-height: 24px;
      }
  
      .share {
        a {
          margin-right: 30px;
  
          &:last-child {
            margin-right: 0;
          }
        }
      }
  
      .alert-text {
        font-weight: 700;
        color: $color-white;
      }
  
      #accordion-footer {
        .panel-heading {
          .panel-title {
            font-size: 14px;
          }
        }
  
        .panel-body {
          font-size: 14px;
        }
      }
    }
  
    section {
      .footer-container {
        @include respond-to('md') {
          padding-left: 50px;
        }
      }
    }
  
  }
  
  //footer