section.new-design {
	@extend %transition;
	position: relative;

	&:after {
		@extend .clearfix;
	}

	.important-message__see-less {
		display: none;
		margin-left: 10px;
		cursor: pointer;
		background: transparent;
		color: #005AA3;
		border: none;
		font-weight: 600;
	}

	ul.navbar-nav {
		padding: 0;

		li {
			margin: 0;
		}

		>li.dropdown.open .dropdown-menu {
			@include respond-to("md") {
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				right: 0;
				margin: auto;
				max-width: 1280px;
				font-size: 16px;
			}
		}
	}

	.navbar.navbar-default {
		background: $color-blue;
		border: none;
		z-index: 4;
		margin-bottom: 0;
		border-radius: 0;
		padding-bottom: 0;
		box-shadow: none;
		width: 100%;

		a.navbar-brand {
			//delete old design
			width: unset;
			height: auto;
			padding: 0;

			img {
				width: 120px;

				@include respond-to("lg") {
					width: 173px;
					margin-right: 20px;
				}
			}
		}

		.container-fluid {
			max-width: 1280px;
			display: flex;
			align-items: center;
			width: 95%;
			height: 56px;

			@include respond-to("lg") {
				height: 84px;
			}
		}

		.navbar-collapse {
			border: none;

			@include respond-to('lg-down') {
				border-top: 1px solid transparent;
				box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);

				&.collapse {
					display: none !important;
				}

				&.collapse.in {
					display: block !important;
				}
			}
		}

		.navbar-nav {
			>li {
				>a {
					color: $color-white;
					font-weight: 700;
					text-transform: capitalize;
					line-height: 55px;
					font-size: 16px;
					height: 69px;
					border-bottom: 4px solid transparent;
					padding: 0;
					margin: 15px 20px;
					margin-bottom: 0;

					&:hover,
					&:focus {
						border-bottom: 4px solid $color-cyan;
						outline: none;
					}
				}

				&.open {
					>a {
						background: none;
						@extend %transition;
						border-bottom: 4px solid $color-cyan;

						&:hover,
						&:focus {
							color: $color-white;
						}
					}
				}
			}
		}

		.navbar-toggle {
			border: none;
			float: left;
			margin: 0;
			margin-left: 8px;
			padding-right: 0;
			display: none;

			&:hover,
			&:focus {
				background: none;
			}

			&:focus {
				outline: -webkit-focus-ring-color auto 1px;
			}

			&.collapsed {
				&:after {
					content: none;
				}
			}

			~.icon-close {
				width: 40px;
				height: 40px;
				background: url('../images/new-icons/close-menu-mobile.svg') no-repeat;
				margin-top: 3px;
				margin-left: 20px;
				margin-right: -10px;
			}

			.icon-bar {
				width: 20px;
				background-color: $color-white;
			}

			&.collapsed {
				display: block;

				~.icon-close {
					display: none;
				}
			}
		}

		.nav-center {
			@extend .flex-center;
			padding-left: 0;
		}

		.nav-right {
			@extend .flex-center;
			justify-content: flex-end;
			flex: 1;

			.avatar-container {
				position: relative;
				display: inline-block;

				@include respond-to ("lg-down"){
					color: white;
					font-size: 10px;
					display: flex;
					flex-direction: column;
					padding-top: 5px;
				}

				img {
					margin-left: 8px;
				}

				.avatar-hot {
					height: 24px;
					width: 24px;
				}

				.avatar-cold {
					height: 24px;
					width: 23px;
					vertical-align: text-top;
				}

				.fa.fa-circle {
					position: absolute;
					top: 28px;
					left: 25px;
					font-size: em(12);
				}

				span.badge-notification {

					position: absolute;
					top: -4px;
					right: 10px;
				}
				.icon-text{
					text-align: center;
				}
			}

			.nav-right-desktop {
				position: relative;
				@extend .flex-center;
				margin-right: -10px;

				>ul,
				>li {
					padding: 0;
					margin: 0;
					list-style: none;
				}

				>.dropdown {
					>a {
						display: block;
						height: 84px;
						line-height: 84px;
						border-bottom: 4px solid transparent;
						@extend %transition;

						&:focus,
						&:hover {
							outline: none;
							border-bottom: 4px solid $color-cyan;
						}
					}


					.badge-notification {
						width: 19px;
						height: 18px;
						background: #da5666;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-pack: center;
						-ms-flex-pack: center;
						justify-content: center;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						color: white;
						z-index: -1;
						border-radius: 3px;
						z-index: 1;
						margin-left: 5px;
						left: 14px;
						top: 20px;
					}
				}

				.navbar-text {
					font-weight: 600;
					color: $color-white;
					margin: 0;
					display: flex;
					align-items: center;

					.name {
						min-width: 58px;
						@extend %ellipsis;
					}
				}

				&.warm-hot {
					.dropdown {
						.up-down-arrow {
							width: 10px;
							height: 8px;
							@extend %arrow-down-custom;
						}

						&.open {
							.up-down-arrow {
								@extend %arrow-up-custom;
							}
						}
					}
				}

				.divider {
					width: 1px;
					height: 43px;
					background: $color-white;
					opacity: 0.3;
					margin: 0 20px;
				}

				.scWebEditInput {
					width: unset;
				}

				.login-curtain {
					display: none;
					position: absolute;
					width: 235px;
					padding: 25px 20px;
					background-color: white;
					border-bottom-left-radius: 10px;
					border-bottom-right-radius: 10px;
					left: 50%;
					-webkit-transform: translateX(-50%);
					transform: translateX(-50%);
					box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.2);

					a.btn {
						display: block;
						width: 100%;
						padding: 8px;
						font-size: 14px;
						font-weight: 600;
						margin-bottom: 10px;
					}

					p {
						font-size: 11px;
						font-weight: 600;
						color: $color-blue;
						margin-bottom: 0;
						padding-bottom: 0;
						line-height: 1;
						text-align: center;
					}
				}
			}

			.nav-right-mobile {
				@extend .flex-center;

				#searchButtonMobile.collapsed {
					.close-search-mobile {
						display: none;
					}

					.open-search-mobile {
						display: block;
					}
				}

				#searchButtonMobile {
					.navbar-mobile-search {
						display: flex
					}

					.close-search-mobile {
						height: 26px;
						display: block;
					}

					.open-search-mobile {
						display: none;
						height: 24px;
						padding-right: 11px;
					}
				}

				.close-icon {
					vertical-align: middle;
					width: 10px;
					color: transparent;
					font-size: 20px;
					margin-left: 0;
					line-height: 1;
				}

				.fa.fa-circle {
					top: 0 !important;
				}

				.profile-toggle {
					&:focus {
						outline: none;
						-webkit-box-shadow: none;
						box-shadow: none;
					}

					background: transparent;
					border: none;

					span.badge-notification {
						width: 19px;
						height: 18px;
						background: #da5666;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-pack: center;
						-ms-flex-pack: center;
						justify-content: center;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						color: white;
						z-index: -1;
						border-radius: 3px;
						position: absolute;
						top: -4px;
						right: -11px !important;
					}
				}

				&.warm-hot {
					.up-down-arrow {
						width: 10px;
						height: 6px;
						@extend %arrow-down-custom;
					}

					.close-icon {
						display: none;
					}

					.collapsed {
						.up-down-arrow {
							display: none;
						}

						.close-icon {
							color: $color-primary-beige;
							display: inline-block;
						}
					}
				}

				&.cold {
					.avatar-cold {
						margin-right: 4px;
					}

					.avatar-cold-circle {
						display: none;
					}

					.collapsed {
						.avatar-cold {
							display: none;
						}

						.avatar-cold-circle {
							display: inline-block;
						}

						.close-icon {
							margin-top: 0px;
							margin-left: 1px;
							color: $color-primary-beige;
							display: inline-block;
						}
					}
				}
			}
		}

		.mega-dropdown-menu {
			padding-top: 0;
			padding-bottom: 40px;
			border: none;

			.container {
				@include respond-to("md") {
					width: inherit;
				}

				padding-left: 193px;
				margin-left: 15px;

				li,
				ul {
					list-style: none;
					padding: 0;
					margin: 0;
				}

				>li {
					margin-right: 40px;
					margin-top: 30px;

					li.dropdown-header {
						color: $color-alto-dark;
						font-size: 12px;
						line-height: 16.5px;
						padding-bottom: 10px;
						display: flex;
						align-items: center;
						font-weight: 700;

						&:not(:first-child) {
							padding-top: 30px;
						}

						&:after {
							flex-shrink: 1;
							flex-grow: 1;
							content: "";
							display: block;
							background-color: $color-primary-beige-medium;
							height: 1px;
							margin-left: 0.6em;
							margin-top: 2px;
						}
					}
				}

				a {
					line-height: 30px;
					display: block;
					color: $color-blue;
					font-weight: 400;
					padding-bottom: 10px;
					padding-top: 5px;
					font-size: 14px;
				}

				&.five-columns {
					display: grid;
					grid-template-columns: repeat(5, 1fr);
					grid-template-rows: 1fr;
					grid-column-gap: 0px;
					grid-row-gap: 0px;
					margin-right: 15px;
					padding-left: 15px;
					width: 100%;

					.col1 {
						grid-area: 1 / 1 / 2 / 2;
					}

					.col2 {
						grid-area: 1 / 2 / 2 / 3;
					}

					.col3 {
						grid-area: 1 / 3 / 2 / 4;
					}

					.col4 {
						grid-area: 1 / 4 / 2 / 5;
					}

					.col5 {
						grid-area: 1 / 5 / 2 / 6;
					}

				}

				&.five-columns-new {
					padding: 0px;

					>li {
						padding-right: 40px;
						padding-top: 30px;
						margin: 0;
					}

					li {
						a {
							line-height: 24px;
						}
					}
				}
			}

			&.new-navigation {
				padding-bottom: 0px;
				border-bottom-left-radius: 0px !important;
				border-bottom-right-radius: 0px !important;

				.row1 {
					.col1 {
						grid-area: 1 / 1 / 2 / 2;
						padding-left: 15px;
					}

					.col2 {
						grid-area: 1 / 2 / 2 / 3;
					}

					.col3 {
						grid-area: 1 / 3 / 2 / 4;
					}

					.col4 {
						grid-area: 1 / 4 / 2 / 5;
					}

					.col5 {
						grid-area: 1 / 5 / 2 / 5;
						background-color: #E9EEF5;
						padding-right: 27px;
						margin: 0;
						padding-left: 22px;
					}
				}

				.row2 {
					li {
						ul {
							padding-bottom: 20px;
						}

					}

					.col1 {
						grid-area: 2 / 1 / 2 / 2;
						padding-left: 15px;
					}

					.col2 {
						grid-area: 2 / 2 / 2 / 3;
					}

					.col3 {
						grid-area: 2 / 3 / 2 / 4;
					}

					.col4 {
						grid-area: 2 / 4 / 2 / 5;
					}

					.col5 {
						grid-area: 2 / 5 / 2 / 5;
						background-color: #E9EEF5;
						padding-right: 27px;
						margin: 0;
						padding-left: 22px;
					}
				}

				.container {
					&.five-columns-new {
						display: grid;
						grid-template-columns: repeat(5, 1fr);
						grid-template-rows: 1fr;
						grid-column-gap: 0px;
						grid-row-gap: 0px;
						margin-right: 0px;
						padding-left: 0px;

						.new-dropdown-header {
							a{
								border-bottom: 1px solid $color-primary-beige-medium;
							color: $color-blue;
							font-size: 16px;
							font-weight: 700;
							line-height: 30px;
							}
							
						}

						li {
							a {
								color: #000;
								font-size: 14px;
								font-weight: 400;
								line-height: 24px;
								padding-bottom: 5px;
							}
						}

						a.see-all {
							color: $color-blue;
							font-size: 14px;
							font-weight: 600;
							line-height: 24px;

							&:after {
								content: " ";
								width: 15px;
								height: 15px;
								display: inline-block;
								vertical-align: top;
								margin-top: 4px;
								margin-left: 10px;
								background: url(../images/new-icons/arrow-right.svg) no-repeat;
							}
						}
					}
				}
			}

			&.resources-menu {
				border-bottom-left-radius: 0px !important;
				border-bottom-right-radius: 0px !important;

				a {
					color: #000 !important;
				}
			}
		}

		.mega-dropdown {
			position: static !important;
			width: 100%;
			padding: 0;

			@include respond-to("lg") {
				width: auto;
			}

			>a.dropdown-toggle {
				&:after {
					@extend %arrow-down-custom;
					margin-left: 6px;
					width: 10px;
					height: 8px;
				}
			}

			&.open {
				>a.dropdown-toggle {
					&:after {
						@extend %arrow-up-custom;
						margin-left: 5px;
						width: 11px;
						height: 9px;
					}
				}
			}

			&.no-icon {
				>a.dropdown-toggle {
					&:after {
						content: " ";
					}
				}
			}
		}
	}

	.card-container {
		@extend .flex-center;

		.header-card {
			max-width: 121px;
			height: auto;

			@include respond-to("lg") {
				max-width: 70px;
			}
		}

		div.card-text {
			display: flex;
			flex-direction: column;
			font-weight: 600;
			margin-left: 20px;

			@include respond-to("lg") {
				width: 140px;
				margin-left: 7px;
				margin-right: -10px;
			}

			.product-name {
				color: black;
				font-size: 14px;
				line-height: 1.4;
				padding-bottom: 2px;
				width: 70%;

				@include respond-to("lg") {
					font-size: 10px;
					width: 100%;
					color: white;
				}
			}

			.product-action {
				text-transform: uppercase;
				font-size: 16px;
				letter-spacing: 0.0556em;
				color: $color-blue;
				font-weight: 600;

				@include respond-to("lg") {
					color: $color-white;
					font-size: 10px;
					line-height: 14px;
				}

				@include respond-to("md-down") {
					&::after {
						background: url(../images/new-icons/arrow-right.svg) no-repeat;
						content: " ";
						color: #81939F;
						width: 16px;
						height: 16px;
						display: inline-block;
						vertical-align: middle;
						margin-left: 10px;
					}
				}
			}
		}

		i {
			&:after {
				content: " ";
				vertical-align: middle;
				display: inline-block;
				background: url(../images/new-icons/chevron-right-link-blue.svg) no-repeat;
				width: 26px;
				height: 26px;

				@include respond-to("lg") {
					background: url(../images/new-icons/chevron-right-link-white.svg) no-repeat;
				}
			}
		}
	}

	.alert-text {
		font-weight: 700;
		font-style: italic;
		color: $color-blue;
	}

	.account-menu {
		padding: 10px 25px;
		min-width: 480px;
		left: -180%;
		min-height: 192px;

		&.pointed {
			filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
			box-shadow: none;
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
			background: transparent;
			padding: 0;
			border: none;
			max-width: 100%;

			.login-curtain__container {
				padding-bottom: 40px;
			}
		}

		.account-menu-intern {
			padding: 25px 20px;
			padding-top: 15px;
			background: white;
			clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0% 85%);

			&.hot {
				padding-top: 0px;
			}
		}

		.row {
			margin: 0px;
		}

		.sign-in {
			text-align: center;
			display: flex;

			.profile-actions {
				border-bottom: 2px solid #D9D5CB;

				p {
					font-size: 14px;
					color: #005AA3;
				}

				a {
					font-size: 14px;
					font-weight: 400;
					width: 100%;
					color: #005AA3;
					line-height: 23px;

					&.btn:first-child {
						color: white;
						font-weight: 500;
					}

					&:last-child {
						padding-top: 10px;

					}

					&.blue {
						display: block;
					}

					&.btn {
						background: #BD5500;
						width: 40%;
						padding: 5px;
					}
				}
				.member{
					color: #6e6e6e;
					font-size: 16px;
					font-weight: 600;
				}
			}
		}

		.actions {
			display: flex;

			.profile-links {
				padding-top: 0px;
				margin-top: 10px;
				flex: 1;

				&:first-child {
					// border-right: 1px solid #D9D5CB;
					padding-left: 0px;
				}

				&:last-child {
					padding-right: 0px;
					border-left: 1px solid #D9D5CB;
					padding-left: 20px;

					a.profile-menu-accordion {
						padding-bottom: 25px;

						&.active {
							padding-bottom: 0px;
						}
					}
				}

				a {
					display: flex;
					padding: 8px 0;

					&.disabled {
						pointer-events: none;
						color: #6e6e6e;
					}

					.badge-notification {
						width: 19px;
						height: 18px;
						background: #da5666;
						display: flex;
						justify-content: center;
						align-items: center;
						color: white;
						z-index: -1;
						border-radius: 3px;
					}

					.fa.fa-circle {
						top: 13px;
						right: -5px;
					}
				}

				span {
					&.actions-title {
						font-size: 16px;
						color: #6e6e6e;
						text-transform: capitalize;
						margin: 0 auto;
						display: block;
						text-align: center;
						font-weight: 700;
						padding-bottom: 10px;
					}
				}

				.profile-menu-accordion:after {
					float: none;
				}

				.profile-menu-actions {
					background-color: transparent;

					li {
						display: block;
						clear: both;
						padding: 0px;
						width: 80% !important;
						margin: 0 auto;

						&:last-of-type {
							padding-bottom: 5px;
						}

						a {
							padding: 0px;
							border: 0;
							width: 95% !important;

							&::before {
								content: '>\00a0';
							}
						}
					}
				}
			}
		}

		.sign-out {
			padding-top: 20px;
			min-height: 24px;

			a {
				font-size: 16px;
				color: #6e6e6e;
				text-transform: capitalize;
				margin: 0 auto;
				display: block;
				text-align: center;
				text-decoration: underline;
				font-weight: 700;
			}
		}

		#message-center-menu {
			.messages {
				padding: 10px 0px 0px;

				.close {
					font-size: 22px;
					font-weight: 600;
					color: #6e6e6e;
					float: right;
					line-height: 1;
				}

				.messages-title {
					font-size: 18px;
					color: #6e6e6e;
					text-transform: capitalize;
					font-weight: 700;
				}

				.divider {
					background-color: #6e6e6e;
					width: 100%;
					height: 1px;
					margin: 0px auto;
					margin-top: 10px;
					opacity: 100;
				}

				.message {
					padding-top: 15px;
					padding-left: 10px;

					.message-subject {
						color: #005AA3;
						font-weight: 700;
						font-size: 14px;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: baseline;
						-ms-flex-align: baseline;
						align-items: baseline;
						-webkit-box-pack: justify;
						-ms-flex-pack: justify;
						margin: 5px 0;
						padding: 5px 0;
						justify-content: space-between;
						line-height: 1;

					}

					.message-description {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: baseline;
						-ms-flex-align: baseline;
						align-items: baseline;

						span {
							color: #005AA3;
							text-decoration: underline;
							font-size: 11px;
							padding-left: 5px;
							cursor: pointer;
						}
					}

					&.message-expanded {
						.message-description {
							.message-body {
								text-overflow: unset;
								max-width: 100%;
								white-space: unset;
								overflow: visible;
								margin: 5px 0;
								padding: 5px 0;
							}
						}
					}


				}

				.messages-preview {
					list-style: none;
					margin: 0px;

					.message-body {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						max-width: 100%;
						font-size: 14px;
						font-weight: 600;
						margin-bottom: 0;
						padding-bottom: 0;
						line-height: 1.5;
						padding: 0;
						margin: 0;
					}

					li.message {
						line-height: 1.7;
						margin: 15px 0;
						padding: 0px;
					}

				}

				.messages-detailed {
					width: 95%;

					.message-body {
						padding-top: 15px;
					}

					.close-detailed {
						font-size: 18px;
						font-weight: 500;
						color: #005AA3;
					}
				}
			}
		}
	}

	.arrow-down {
		width: 0;
		height: 0;
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;

		border-top: 20px solid #f00;
	}

	.profile-menu {
		min-width: 264px;
		box-shadow: 0px 12px 20px rgba(0, 45, 82, 0.5);
		top: 82px;
		right: -60px;
		left: auto;
		border-radius: 8px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border: none;
		padding: 40px 25px;

		a {
			font-size: 14px;
			font-weight: 400;
			width: 100%;
			color: $color-blue;
			line-height: 23px;

			&:empty {
				display: none;
			}
		}

	}

	.profile-links {
		@extend %profile-links;

		>a {
			&:last-child {
				// border-bottom: 1px solid $color-primary-beige-medium;
				padding-bottom: 20px;
			}
		}

		&:after {
			@extend .clearfix;
		}
	}

	.profile-actions {
		span {
			color: $color-alto-dark;

			&.blue {
				color: $color-blue !important;
				text-decoration: underline;
			}
		}

		.pipeline {
			padding-right: 10px;
			padding-left: 10px;

			&::after {
				content: "\007C";
			}
		}
	}

	.profile-menu-accordion {
		&.active {
			&:after {
				@extend %arrow-up-fa;
				margin-left: 5px;
			}
		}

		&:after {
			@extend %arrow-down-fa;
			margin-left: 5px;
		}

		&:hover,
		&:focus {
			outline: none;
			text-decoration: none;
		}
	}

	.profile-menu-actions {
		background-color: $color-primary-beige;

		@include respond-to("lg") {
			margin-left: -25px;
			margin-right: -25px;
		}

		display: none;
		overflow: hidden;
		padding-bottom: 0;

		li {
			margin: 0;
			position: relative;

			&:last-of-type {
				a {
					border-bottom: 0;
				}
			}
		}

		a {
			display: block;
			clear: both;
			padding: 10px 0px;
			border-bottom: 1px solid #e7e4df;
			width: 80% !important;
			margin: 0 auto;
		}

		.fa.fa-circle {
			right: 25px;
			@extend %fa-circle;
		}
	}

	.state-img {
		width: 58px;
		margin-right: 10px;
		border-radius: 4px;
	}

	//Mobile Layers
	.overlay-mobile {
		height: 0%;
		width: 100%;
		position: fixed;
		z-index: 50;
		left: 0;
		background-color: #f5f4f1;
		overflow-y: auto;
		transition: .5s ease;
		opacity: 0;

		.overlay-content {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: auto;

			>ul {
				list-style: none;
				margin: 0;

				// first level menu links
				>li {
					margin: 0;

					&:nth-last-child(2) {
						>a:last-child {
							border-bottom: none;
						}
					}

					>a {

						&:focus,
						&:hover {
							outline: none;
						}
					}
				}

				// first level menu
				a {
					margin: 0px 24px;
					padding: 24px 0;
					text-decoration: none;
					font-size: 18px;
					color: $color-blue;
					display: block;
					transition: 0.3s;
					line-height: 25px;
					font-weight: 700;
					border-bottom: 1px solid #e7e4df;

					&.n-b-b {
						border-bottom: 0;
					}

					&.dropdown-toggle {
						&:after {
							@extend %arrow-down-blue;
							width: 15px;
							height: 8px;
							position: relative;
							float: right;
							top: 8px;
						}
					}
				}

				.open {
					>a.dropdown-toggle {
						border-bottom: 1px solid transparent;

						&:after {
							@extend %arrow-up-blue;
						}
					}
				}
			}

			// second level menu - position
			.open ul.dropdown-menu {
				position: static;
				float: none;
				width: auto;
				border: none;
				padding: 0;
				margin: 0;
				box-shadow: none;
			}

			// second level menu
			ul.dropdown-menu {
				background-color: $color-primary-beige;


				>li {
					// margin: 0 24px !important;

					@include respond-to("md") {
						display: list-item;
						height: auto;
					}

					>a {
						color: $color-blue;
						font-size: 16px;
						line-height: 52px;
						padding: 0;
						margin: 0;
						font-weight: 400;

						&.disabled {
							pointer-events: none;
							color: #6e6e6e;
						}

						&:focus {
							outline: none;
						}
					}
				}

				&.navigation {
					li {
						margin: 0 24px;
					}
				}
			}

			// third level menu
			.dropdown-submenu {
				position: relative;

				>a {
					text-transform: capitalize;
					display: block;

					&:after {
						@extend %arrow-down-fa;
						margin-right: 12px;
					}

					&.open {
						&:after {
							@extend %arrow-up-fa;
						}

						border-bottom: 1px solid transparent;
					}
				}

				.dropdown-menu {
					a {
						font-size: 14px;
						padding: 0 16px;
						border-bottom: 1px solid #f4f3ef;

						&:hover,
						&:focus {
							background-color: inherit;
						}
					}
				}

				.open~ul.dropdown-menu {
					background-color: $color-white;
					margin-right: -24px;
					margin-left: -24px;
					border-radius: 0px;
				}
			}

			.new-mobile-menu {
				&.dropdown-menu {
					>li {
						>a {
							font-weight: 600;
						}
					}
				}

				.dropdown-submenu {
					.dropdown-menu {
						a {
							padding: 0 16px;
							font-weight: 400;
							color: #000;

							&.see-all {
								color: $color-blue;
								padding: 8px 16px;
								font-weight: 600;

								&:after {
									content: " ";
									width: 15px;
									height: 15px;
									display: inline-block;
									vertical-align: top;
									margin-top: 4px;
									margin-left: 10px;
									background: url(../images/new-icons/arrow-right.svg) no-repeat;
								}
							}
						}
					}
				}

				.mobile-blue {
					background-color: #E7EFF6;
					margin: 0 !important;
					padding: 0 24px;

					a:hover {
						background-color: #E7EFF6;
					}
				}
			}

			.resources-menu {
				li {
					margin: 0 24px;
					a{
						font-weight: 600;
					}
				}
			}
		}

		#profile-menu {
			background-color: #fff;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-pack: justify;
			-ms-flex-pack: justify;
			justify-content: space-between;

			.p-0 {
				padding: 0px !important;
			}

			.mobile-profile-menu__hot {
				padding: 0;

				.navbar-collapse {
					max-width: 100%;
					box-sizing: border-box;
					padding: 0px;
					margin: 0px;
				}

				.navbar {
					.navbar-nav {
						padding: 0;
						margin: 0;
						width: 100%;
						display: flex;
						flex-direction: column;

						.dropdown-menu {
							border: none;
							border-radius: 0;
							padding: 0;
							margin: 0;

							.dropdown-item {
								padding: 4px 20px 3px !important;
								display: flex !important;

								&:active {
									background-color: transparent;
								}

							}

							.badge-notification {
								width: 19px;
								height: 18px;
								background: #da5666;
								display: -webkit-box;
								display: -ms-flexbox;
								display: flex;
								-webkit-box-pack: center;
								-ms-flex-pack: center;
								justify-content: center;
								-webkit-box-align: center;
								-ms-flex-align: center;
								align-items: center;
								color: white;
								z-index: -1;
								border-radius: 3px;
								z-index: 1;
								margin-left: 5px;
							}

							.important-messages,
							.mobile-important-messages {
								display: flex !important;
								justify-content: start;
								align-items: center;
							}

							.mobile-menu-imortant-messages {
								background: white;
								padding: 15px 20px;

								&.messages-preview {
									list-style: none;

									.message-body {
										overflow: hidden;
										white-space: nowrap;
										text-overflow: ellipsis;
										max-width: 100%;
										font-size: 14px;
										font-weight: 600;
										margin-bottom: 0;
										padding-bottom: 0;
										line-height: 1.5;
										padding: 0;
										margin: 0;

										a {
											margin: 0px !important;
										}
									}

									.message-subject {
										color: #005AA3;
										font-weight: 700;
										font-size: 14px;
										display: -webkit-box;
										display: -ms-flexbox;
										display: flex;
										-webkit-box-align: baseline;
										-ms-flex-align: baseline;
										align-items: baseline;
										-webkit-box-pack: justify;
										-ms-flex-pack: justify;
										margin: 5px 0;
										padding: 5px 0;
										justify-content: space-between;
										line-height: 1.5;

									}

									.message-description {
										display: -webkit-box;
										display: -ms-flexbox;
										display: flex;
										-webkit-box-align: baseline;
										-ms-flex-align: baseline;
										align-items: baseline;

										span {
											color: #005AA3;
											text-decoration: underline;
											font-size: 11px;
											padding-left: 5px;
											cursor: pointer;
											display: none;
										}
									}

									.message {
										&.message-expanded {
											.message-description {
												.message-body {
													text-overflow: unset;
													max-width: 100%;
													white-space: unset;
													overflow: visible;
													margin: 5px 0;
													padding: 5px 0;
												}
											}
										}
									}

									li.message {
										line-height: 1.7;
										margin: 15px 0;
										padding: 0px;
									}

								}

								.messages-detailed {
									width: 95%;

									.message-body {
										padding-top: 15px;
									}

									.close-detailed {
										font-size: 18px;
										font-weight: 500;
										color: #005AA3;
									}
								}
							}
						}

						li {
							margin: 0;
							list-style-type: none;
						}
					}

					.dropdown-toggle {
						padding: 15px 20px;
						display: block;

						&::after {
							display: inline-block;
							margin-left: 0.255em;
							vertical-align: 0.255em;
							content: "";
							border-top: 0.3em solid;
							border-right: 0.3em solid transparent;
							border-bottom: 0;
							border-left: 0.3em solid transparent;
						}

						&.nav-link {
							font-weight: bold;
							color: #6e6e6e;
							padding-top: 20px;
							padding-bottom: 19px;
							border-bottom: 1px solid #f4f3ef;

							&:not(:last-of-type) {
								border-bottom: 1px solid #f4f3ef;
							}
						}
					}

					.message-list {
						max-width: 100%;
						width: 100%;

						.message-title {
							color: $color-purple;
							font-weight: bold;

							.important-message__see-less {
								display: none;
								border: none;
								background: transparent;
							}
						}

						&.message-expanded {
							.message-description {
								p {
									white-space: unset;
								}
							}
						}

						.message-description {
							display: flex;
							align-items: baseline;

							p {
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								// max-width: calc(100vw - 15%);
							}

							.important-message__see-more {
								color: $color-purple;
								font-size: 11px;
								padding-left: 5px;
								text-decoration: underline;
								cursor: pointer;
							}
						}

					}
				}

				.mobile-profile-menu {
					padding: 15px 24px;
					background: #f5f4f1;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.user-name-image {
						display: flex;
						align-items: center;

						.user-name {
							font-weight: bold;

						}
					}

					a {
						color: $color-purple;
						text-decoration: underline;
						font-size: 14px;
					}

					.profile-name {
						display: flex;
						img {
							width: 58px;
							margin: auto 10px auto 0;
							height: fit-content;
						}
						div{
							display: flex;
							flex-direction: column;
							.member{
								color: #6e6e6e;
								font-size: 14px;
								margin: 0;
								padding: 0;
							}
						}
					}
				}

				.navbar-expand-xg {
					.container-fluid {
						.navbar-collapse {
							.navbar-nav {
								.nav-item {
									.dropdown-menu {
										li {
											a {
												padding-left: 0px !important;
												margin: 0px 20px;


												&:last-child {
													padding-bottom: 4px !important;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			.profile-header {
				height: 72px;
				display: flex;
				align-items: center;
				margin: 0 24px;

				.profile-name {
					img {
						@extend .state-img;
					}

					span.name {
						font-weight: 600;
						font-size: 16px;
						color: $color-alto-dark;
					}
				}

				.profile-actions {
					justify-content: flex-end;
					display: flex;
					flex: 1;

					a {
						font-size: 12px;
						color: $color-alto-dark;
						font-weight: 400;
						padding-right: 6px;
					}

					.pipeline {
						font-size: 12px;
						padding-right: 12px;
					}
				}
			}

			.profile-links {
				background-color: $color-white;
				padding: 0 24px;

				>a {
					font-size: 16px;
					border-bottom: 1px solid #f4f3ef;
					line-height: 50px;
					padding-top: 0;
					padding-bottom: 0;
					font-weight: bold;
					color: #6e6e6e;

					&:not(:last-of-type) {
						border-bottom: 1px solid #f4f3ef;
					}
				}
			}

			.profile-menu-actions {
				a {
					padding: 10px 25px;
					font-size: 14px;
					width: 100% !important;
					position: relative;
				}

				.fa.fa-circle {
					right: 25px;
					@extend %fa-circle;
				}
			}

			.cash-rewards {
				background-color: #f5f4f1;
				padding: 10px 0 20px;
			}
		}

		.get-started {
			background-color: $color-white;
			padding: 40px 24px;

			.actions {
				.btn {
					background-color: $color-blue;
					padding: 10px 30px;
					font-size: 16px;
					color: $color-white;
					font-weight: 600;

					&:hover,
					&:focus {
						background-color: $color-cta-primary-roll;
					}
				}

				.link {
					display: block;
					padding-top: 20px;
					margin-bottom: 20px;
				}
			}
		}

		.card-container {
			margin: 24px;
			margin-bottom: 5px;
		}

		.dropdown-backdrop {
			display: none;
		}
	}
}

html.disableScrollSafari,
body.disableScrollSafari {
	overflow-y: hidden !important;
	position: fixed !important;
	height: 100% !important;
}

.subnav-headline {
	.slick-disabled {
		display: none !important;
	}

	display: grid;
	grid-template-columns: 8% 84% 8%;
	background-color: $color-gray;
	height: 56px;

	@include respond-to("sm") {
		grid-template-columns: 10% 80% 10%;
	}

	@include respond-to("md") {
		display: flex;
		height: 84px;
	}

	.subnav-links-container {
		@include respond-to("md") {
			display: flex;
			align-items: center;
			max-width: 1200px;
			width: 95%;
			margin: 0 auto;
		}

		@include respond-to("lg") {
			max-width: 1280px;
		}

		.menu-slider {
			@include respond-to("md") {
				display: flex;
			}

			.slick-slide {
				align-items: center;
				justify-content: center;
			}

			a {
				display: flex !important;
				height: 56px;
				align-items: center;
				justify-content: center;
				font-size: 14.5px;

				&:hover,
				&:focus {
					color: $color-blue;
				}

				@include respond-to("md") {
					height: unset;
					padding-left: 15px;
					padding-right: 15px;

					// &:first-of-type {
					// 	padding-left: 0;
					// }
				}

				@include respond-to("lg") {
					font-size: 16px;
					padding-left: 15px;
					padding-right: 15px;
				}

				&.active {
					font-weight: bold;
				}

				&.right-cta {
					height: 40px;

					@include respond-to("md") {
						display: none !important;
					}
				}
			}
		}

		a.right-cta {
			background-color: white;
			font-weight: bold;
			margin-left: auto;
			padding: 10px 25px;
			border-radius: 4px;

			@include respond-to("md-down") {
				display: none;
			}
		}
	}

	.subnav-slider-navigation {

		.back-arrow,
		.next-arrow {
			cursor: pointer;
			font-size: 34px;
			width: 100%;
			display: inline-block;
			text-align: center;
			padding: 0;
			height: 80%;
			color: #6e6e6e;

			span {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.back-arrow {
			border-right: 1px solid $color-text-gray;
		}

		.next-arrow {
			border-left: 1px solid $color-text-gray;
		}
	}
}