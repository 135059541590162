ul {
    margin: 0px 20px;
    list-style-type: disc;
    list-style-position: outside;
    padding:0 0 30px 0;
    &.strong{
        font-weight: 700;
    }
    li{
        line-height: 1.7;
        margin: 15px 0;
        p{
            color: black;
            line-height:2;
        }
        a{
            line-height:2; 
        }
    }//li
    &.checked{
        margin:0;
        list-style-type: none;
        padding: 25px 0;
        li{
            margin: 0;
            color:$color-black-alpha;
            padding-bottom: 8px;

            &:last-child{
                padding-bottom: 0;
            }
            &:before{
                content:"\f00c";
                font-family: fontAwesome;
                float: left;
                margin-right: 10px;
            }
            &:after {
                clear: both;
                content: " ";
                width: 100%;
                display: block;
            }
            span{
                float: left;

            }
        }//li
        &.aquamarine{
            li{
                &:before{
                    color:$color-aquamarine;
                }
            }
        }//green
    }//checked
    &.checked-big {
        margin:0;
        list-style-type: none;
        &:after{
            @extend %clear;
        }
        @include respond-to('md'){
            margin:40px auto;
            margin-bottom: 0px;
            padding-bottom: 0px;
        }
        &.divided{
            margin-top: 0;
            li{
                &:first-child{
                    padding-top: 0;
                }
            }
        }//divided
        li {
            //width: 100%;
            margin: 0;
            padding: 20px 0;
            @include respond-to('md'){
                //margin: 20px;
            }

            &:after{
                 @extend %clear;
            }

            span.icon{
                float: left;
                display: block;
                width: 25px;
                height: 25px;
                margin-right: 15px;
                @include respond-to('md'){
                    margin-right: 25px;
                    width: 50px;
                    height: 50px;
                }
                img{
                    width: 100%;
                }
            }
            span.text{
                float: left;
                width: 74%;
                color:$color-black-alpha;
                strong{
                    color:black;
                }
                @include respond-to('md'){
                    display: inline;
                }
            }
        }//li
    }//cheked
    &.winner-list{
        margin:0;
        list-style-type: none;
        color:$color-black-alpha;
        strong{
            color:black;
        }
        img{
            float: left;
            padding:0 25px 30px 0;
        }
        hr{
            margin-top: 25px;
            margin-bottom: 25px;
        }
    }//winner-list
    &.stacked-icons{
        list-style-type: none;
        margin:30px 0;
        padding: 0;
        list-style-type: none;
        li{
            margin: 0;
            margin-bottom: 15px;
            &.custom-chat{
                &:after{
                  @extend %clear;
                }
                span {
                  display: block;
                  float: left;
                  width: 80%;
                  padding: 11px 0 0 10px;
                }
            > div {
                display: inline-block;
                float: left;
                zoom:1;
                width: 50px;
                img{
                  width: 50px;
                  height: 50px;
                }
              }
            }
            a{
                span.text{
                    padding-left: 10px;
                }
            }//a
        }//li
    }
    &.bullets{
        margin:0;
        color: black;
        list-style-type: disc;
        list-style-position: outside;
        max-width: 602px;
        margin-left: 15px;
        padding:0 0 30px 0;
        &.strong{
            font-weight: 700;
        }
        li{
            margin: 0;
            line-height: 1.7;
            p,a{
                color: black;
                line-height:2;
            }
        }//li


    }//bullets
    &.col-1{
        @include respond-to('md'){
            max-width: 550px;
        }
    }//col-2
    &.col-2{
        @include respond-to('md'){
            max-width: 1014px;
            display: flex;
            flex-wrap: wrap;
            li{
                width: 45%;
                margin:0 20px;
            }
        }
    }//col-2
} //ul


ol{
    &.custom-counter{
        padding: 0;
        margin:0;
        //margin-bottom: 50px;
        li{
            counter-increment: step-counter;
            margin-bottom: 50px;
            list-style-type: none;
            position: relative;
            padding-left: 70px;
            min-height: 50px;
            &:last-child{
                margin-bottom: 0;
            }
            &:before{
                content: counter(step-counter);
                counter-increment: item;
                color:white;
                border-radius: 50%;
                background: $color-blue;
                display: block;
                font-size: em(21);
                line-height: 50px;
                height: 50px;
                margin-right: 20px;
                text-align: center;
                vertical-align: middle;
                width: 50px;
                position: absolute;
                top:0;
                left: 0;
            }
            &:after{
                @extend %clear;
            }
        }//li
    }
}//ol

.list-header {
    margin:20px 0;
    font-size:21px;
    padding:30px 0 5px 0;
}
