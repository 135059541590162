$bg-colors: (white:white, 
aquamarine: $color-aquamarine, 
gray: $color-gray, 
gray-dark: $color-gray-dark, 
red:$color-red, 
blue:$color-blue, 
dark-blue:$color-footer, 
green:$color-green, 
cyan:lighten($color-blue, 25%), 
cyan-r: $color-cyan, 
gray-l:lighten($color-gray, 2.5), 
gray-light: $color-beige-light-05);
@each $name,
$color in $bg-colors {
    .bg-#{$name} {
        background: $color;
    }
    .#{$name} {
        color: $color;
    }
} //each