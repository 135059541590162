%transition {
    transition: all 0.5s ease !important;
}

%clear {
    content: " ";
    display: block;
    width: 100%;
    height: 1px;
    clear: both;
}

%ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

%flex-center-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

%text-light {
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
}

%text-regular {
    @extend %text-light;

    font-weight: 400;
}

%text-semibold {
    @extend %text-light;
    font-weight: 600;
}

%font-normal {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
}

%box-shadow-top {
    -moz-box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
}

%box-shadow-bottom {
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.box-shadow-complete {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
}

.box-shadow-top {
    -moz-box-shadow: 5px 0 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 5px 0 4px rgba(0, 0, 0, 0.2);
    box-shadow: 5px 0 4px rgba(0, 0, 0, 0.2);
}

.box-shadow-bottom {
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

%slider-gray-dots {
    .slick-dots {
        li {
            button {
                background: $color-gray-plain;
                @extend %transition;

                &:hover {
                    background: black;
                }
            }

            &.slick-active {
                button {
                    background: black;

                    &:after {
                        content: "";
                    }
                }

                //button
            }

            //slick-active
        }

        //li
    }

    //slick-dots
}

%form-errors {
    color: red !important;
    margin: 3px 0;
    font-size: 13px;
    display: block;
    width: 100%;
    font-weight: normal;
}

%panel-form {
    padding: 30px 0;
    border: none;
    box-shadow: none;

    @include respond-to("md") {
        border: 1px solid transparent;
        -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.05);
        padding: 30px;
    }
}

%genre {
    font-size: 12px;
    color: $color-blue;
    margin-top: 20px;
    margin-bottom: 10px;
    text-transform: uppercase;
    line-height: 1.33;
    letter-spacing: 1px;
}

%content-carousel {
    h2 {
        text-align: center;
    }

    .slider {
        @extend %slider-gray-dots;

        .slick-arrow {
            top: 18%;

            &.slick-next {
                right: -45px;
            }

            &.slick-prev {
                left: -55px;
            }

            //prev
        }

        //.slick-arrow
        .card {
            box-sizing: border-box;
            display: block;

            h3 {
                font-size: em(18);
            }

            p {
                color: rgba(8, 32, 45, 0.5);
            }

            a {
                display: block;
                box-sizing: border-box;
                padding: 0;
                width: 100%;
            }

            .genre {
                @extend %genre;
            }
            .no-genre{
                color: black;
            }
        }

        //item
    }

    //slider
    .slick-list {
        @include respond-to("lg") {
            margin: 0 -30px;
        }
    }

    .slick-slide {
        margin: 0 10px;

        @include respond-to("lg") {
            margin: 0 10px;
        }
    }
}

//content-carousel

%card {
    max-width: 299px;
    margin: 0px 20px 0 0;
    display: flex !important;
    flex-wrap: wrap;
    padding: 50px 40px;
    border-radius: 5px;
    color: $color-black-alpha;
    @extend .box-shadow-complete;

    &.image,
    &.bg-aquamarine {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

        h3 {
            color: white;
        }

        p {
            color: $color-white-alpha;
        }

        .btn {
            @extend .btn-white;
        }
    }

    //image
    h3 {
        padding-bottom: 6px;
        color: black;
    }

    p {
        color: $color-black-alpha;
    }

    a.terms {
        padding-top: 40px;
        color: $color-black-alpha;
        font-size: 14px;
        display: block;
    }

    ul.bullets {
        margin-left: 25px;
        color: $color-black-alpha;

        li {
            margin: 15px 0;

            a {
                color: $color-black-alpha;
            }
        }
    }

    //
    &.bg-gray {
        .btn {
            background: #007A6A;
            color: white;
        }
    }

    &.dark {
        h3 {
            color: black;
        }

        p {
            color: black;
        }

        .btn {
            background: $color-aquamarine !important;
            color: white !important;
        }
    }

    //dark

    @include respond-to("xxs") {
        max-width: 270px;
    }

    @include respond-to("sm") {
        max-width: 320px;
    }

    @include respond-to("md") {
        margin: 0px 30px 0 0;
        max-width: 297px;
    }

    @include respond-to("lg") {
        margin: 0px 30px 0 0;
        max-width: 360px;
    }

    .cta {
        align-self: flex-end;
        text-align: center;
        width: 100%;
        margin: 40px 0;

        &.lessSpace {
            margin: 10px 0;
        }
    }

    .terms {
        padding: 40px 0 10px 0;
        width: 100%;
        text-align: center;

        a {
            @extend %font-normal;
            font-size: 13px;
            line-height: 1.77;
            color: $color-black-alpha;
        }
    }
}

//card

//btn
%btn {
    padding: 15px 30px;
    border-radius: 4px;
    text-align: center;
    font-size: 1.125em; //18px
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    background: $color-gray;
    color: $color-text-dark;
    text-decoration: none !important;
    white-space: normal;
    cursor: pointer;

    @include respond-to("md") {
        font-size: em(15);
    }

    @include respond-to("lg") {
        font-size: em(18); //18px
    }

    &:hover {
        background: darken($color-gray, 7%);
        padding: 15px 30px;
        @extend %transition;

        a {
            color: black;
        }
    }

    a {
        color: black;
    }
}

//btn
%disabled {
    cursor: not-allowed;
    pointer-events: none;
    box-shadow: none;
    opacity: 0.65;
}

%btn-new-design {
    padding: 10px 0;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    min-width: 160px;
    max-width: 190px;
    width: 100%;
}

%new-design {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    border-radius: 4px;
    white-space: normal;
    padding: 9px 20px;
    min-width: $btn-new-width;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);

    &:hover {
        padding: 9px 20px;
        @extend %transition;
    }

    @include respond-to("sm-down") {
        display: block;
    }

    @include respond-to("md") {
        font-size: 16px;
        line-height: 24px;
        padding: 12px 35px;

        &:hover {
            padding: 12px 35px;
        }
    }
}

%profile-links {
    > a {
        padding: 10px 0;
        display: block;
        clear: both;
        position: relative;

        span {
            width: 93%;
            display: block;
            @extend %ellipsis;
        }

        .fa.fa-circle {
            right: 0;
            @extend %fa-circle;
        }
    }
}

%arrow-down-fa {
    font-family: fontAwesome;
    content: "\f107";
    float: right;
}

%arrow-up-fa {
    font-family: fontAwesome;
    content: "\f106";
    float: right;
}

%arrow-down-custom {
    display: inline-block;
    content: " ";
    background: url(../images/new-icons/arrow-down-white.svg) no-repeat;
}

%arrow-up-custom {
    content: " ";
    background: url(../images/new-icons/arrow-up-white.svg) no-repeat;
}

%arrow-down-blue {
    content: " ";
    background: url(../images/new-icons/arrow-down-blue.svg) no-repeat;
}

%arrow-up-blue {
    content: " ";
    background: url(../images/new-icons/arrow-up-blue.svg) no-repeat;
}

%fa-circle {
    position: absolute;
    top: 17px;
    vertical-align: middle;
    font-size: 12px;
}


%internal-page-typography {
    h4 {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.05em;

        @include respond-to("md") {
            font-size: 36px;
            line-height: 48px;
        }
    }

    h5 {  
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.05em;

        @include respond-to("md") {
            font-size: 32px;
            line-height: 38px;
        } 
    }

    h6 {
        font-size: 18px;
        line-height: 24px;
        @include respond-to("md") {
            font-size: 24px;
            line-height: 32px;
        }
    }

    p {
        font-size: 16px;
        line-height: 26px;
        @include respond-to("md") {
            font-size: 18px;
            line-height: 26px;
        }  
    }
}